import { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";

import { editProfile } from "../../app/api/auth"
import { logOut, updateProfile } from "../../app/reducer/auth.reducer";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../app/store";

interface FormProps{
    isVisible: boolean;
    handleClose: (() => void);
    userData: any;
}

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PHONE_REGEX = /^(\+91|\+91\-|0)?[789]\d{9}$/;


export default function ProfileForm({isVisible,handleClose,userData}:FormProps):JSX.Element {
  const [status, setStatus] = useState<0|1|2|3|4>(0); //0: idle, 1:sending, 2:success, 3:error, 4:already exists
  const [name, setName] = useState<string>(userData.full_name?userData.full_name:'');
  const [phone, setPhone] = useState<string>(userData.phone);
  const [email, setEmail] = useState<string>(userData.email);
  const [address, setAddress] = useState<string>(userData.address?userData.address:'');
  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>();

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    setName(userData.full_name?userData.full_name:'');
    setPhone(userData.phone);
    setEmail(userData.email);
    setAddress(userData.address?userData.address:'');

  }, [userData]);

  const handleLogOut = () => {
        
    handleClose();
    dispatch(logOut());

  };

  const onSubmit: SubmitHandler<Inputs> = data => {
    let params = {
      full_name: data.name,
      contact: data.phone,
      email: data.email,
      address: data.address,
      kyc_filename: userData.kyc_filename?userData.kyc_filename:''
    }
    setStatus(1);
    editProfile(params).then( res => {
      setStatus(2)
      console.log("UPDATE", res);
      dispatch(updateProfile(params));
    }
    ).catch( err => {
      console.log("ERR",err)
        setStatus(3)
    }
    )
  };

  type Inputs = {
    name: string,
    phone: string,
    email: string,
    address: string,
    dateOfBirth: string,
  };

  return (
      <Modal show={isVisible} onHide={handleClose}>

      <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Body>
          <div className="modal-title magenta-text" style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
            <div><FontAwesomeIcon icon={faUserCircle} />&nbsp;My Profile</div>
            <Button variant="danger" onClick={()=>handleLogOut()}>
              Log Out
            </Button>
          </div>

          <label className="modal-form-field">
            <text className="required">Name:</text>
            <div className="modal-form-input">
              <input {...register("name", { required: true })}
              value={name} onChange={e=>setName(e.target.value)}
              className={errors.name?"validation-error":""}/>
              {errors.name && <div className="error-text">Please enter name of contact person.</div>}
            </div>
          </label>
          <label className="modal-form-field">
            <text className="required">Phone Number:</text>
            <div className="modal-form-input">
              <input {...register("phone", { required: true, pattern: PHONE_REGEX })}
                value={phone} onChange={e=>setPhone(e.target.value)}
                className={errors.phone?"validation-error":""}/>
              {errors.phone && <div className="error-text">Please enter a valid phone number.</div>}
            </div>
          </label>
          <label className="modal-form-field">
            <text className="required">Email:</text>
            <div className="modal-form-input">
              <input {...register("email", { required: true, pattern: EMAIL_REGEX  })}
               value={email} onChange={e=>setEmail(e.target.value)}
               className={errors.email?"validation-error":""}/>
              {errors.email && <div className="error-text">Please enter a valid email address.</div>}
            </div>
          </label>
          <label className="modal-form-field">
            <text className="required">Address:</text>
            <div className="modal-form-input">
              <textarea {...register("address", { required: true })}
               value={address} onChange={e=>setAddress(e.target.value)}
               className={errors.address?"validation-error":""}/>
              {errors.address && <div className="error-text">Please enter your registered company address.</div>}
            </div>
          </label>
          {/* <form> */}
              {/* <label className="modal-form-field">
                  <text className="required">Company Name:</text>
                  <input
                      aria-label="Company Name"
                      name="company"
                      value={company} onChange={(e) => setCompany(e.target.value)}
                  />
              </label> */}
          {/* </form> */}
          {status===3 &&
            <div className="error-text" style={{textAlign:'center'}}>Something went wrong. Please try again.</div>
          }
      </Modal.Body>
      {status!==2 && <Modal.Footer>
        <Button variant="secondary" onClick={()=>handleClose()} disabled={status===1}>
          Close
        </Button>
        {status===1?
          <Button variant="primary" onClick={(e)=>e.preventDefault()}
            style={{width:80, display:'flex',flexDirection:'row',justifyContent:'center', cursor:'default'}}
          >
            <div className="loader" />
          </Button>
          :
          <input className="btn btn-primary" type="submit" value="Save Changes"/>
        }
      </Modal.Footer>}
        </form>
        {/* {status===2&& */}
        
        {status===2 &&
        <Modal.Footer>
          <label className="modal-form-field">
            <text style={{width:'100%'}}>Changes saved.</text>
          </label>
          <Button variant="success" onClick={()=>handleClose()}>
            OK
          </Button>
        </Modal.Footer>
        }

       
    </Modal>
  
  );

}