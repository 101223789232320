import axios, { AxiosResponse } from "axios";
import { AnyRecord } from "dns";

let mandrilKey = process.env.REACT_APP_MANDRIL_KEY;
let msg91Key = process.env.REACT_APP_MSG91_KEY;
let baseUrl = process.env.REACT_APP_ENQUIRY_BASE_URL;

const localhostUrl = 'http://localhost:8000'
const nodeBaseUrl = process.env.REACT_APP_NODE_BASE_URL;


interface ReviewInputs {
    name: string,
    usertype: string,
    content: string,
    companydetails: {
        companyname: string
        role: string
    },
    servicetype: string,
    rating: number,
    sign: {
        username: string,
        contact: string
    }
};

// axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = baseUrl;
// axios.defaults.headers.post['Access-Control-Allow-Credentials'] = true;
// axios.defaults.headers.post['Authorization'] = `Bearer ${token}`;

export const printLabelApi = async (orderId: string) => {
    return new Promise<{ response: AxiosResponse }>(async (resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/customer/label?order_id=${orderId}`,
            method: "GET",
            headers: {
                Accept: "application/json"
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    })
}

export const printApsrtcLabelApi = async (sm_orderid: string) => {
    return new Promise<{ response: AxiosResponse }>(async (resolve, reject) => {
        axios.request({
            method: 'get',
            maxBodyLength: Infinity,
            url: `${nodeBaseUrl}/get-ecs-invoice-url`,
            params: {
                sm_orderid
            },
            timeout: 5000
        })
            .then((response) => {
                console.log(JSON.stringify(response.data));
                if (response?.data?.success) {
                    // console.log(response?.data?.invoicePdfUrl)
                    resolve({ response: response })
                    // documentHeaderUrl = response?.data?.invoicePdfUrl
                }
            })
            .catch((error) => {
                console.error(error);
                reject({ error: error.response.status })
            });
    })
}

export function getBannerImages() {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/banners`,
            method: "GET",
            headers: {
                Accept: "application/json"
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export function getCoupons() {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/coupons`,
            method: "GET",
            headers: {
                Accept: "application/json"
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export function getCities() {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/cities`,
            method: "GET",
            headers: {
                Accept: "application/json"
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export function getExportCountries() {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/exportCountries`,
            method: "GET",
            headers: {
                Accept: "application/json"
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export function getHyShippers() {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/shippers`,
            method: "GET",
            headers: {
                Accept: "application/json"
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export function getIntShippers() {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/international-shippers`,
            method: "GET",
            headers: {
                Accept: "application/json"
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export function getPanIndiaShippers() {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/panindia-shippers`,
            method: "GET",
            headers: {
                Accept: "application/json"
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export function getCustomerReviews() {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/reviews/approved`,
            method: "GET",
            headers: {
                Accept: "application/json"
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export function createReview(data: ReviewInputs) {
    // console.log(data);

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/reviews`,
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: data
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export function getCMSAnnouncementBarDetails() {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/cms/headers/announcement-bar`,
            method: "GET",
            headers: {
                Accept: "application/json"
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export function getOrderIdForReview(reviewSearchString: string) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/feedback/getOrderIdForReview/${reviewSearchString}`,
            method: "GET",
            headers: {
                Accept: "application/json"
            },
            // data: formData
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export function addOrderReview(orderID: string, review: any, type: string) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/feedback/createReview/`,
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: { orderID, review, type }
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export function getOrderStatus(orderID: string) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/track-order/${orderID}`,
            method: "GET",
            headers: {
                Accept: "application/json"
            },
            // data: formData
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}