import { faAirFreshener, faAngleRight, faBomb, faExclamationTriangle, faFire, faFish, faFlask, faGem, faHandHoldingWater, faMoneyBillAlt, faMoneyCheck, faRadiationAlt, faSkull, faSkullCrossbones, faTimesCircle, faTint, faTree, faUtensils } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect, useRef } from "react";
import { Button, Form, Card, Modal, Col, Row } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import { setCityBounds } from "../../app/reducer/hyperlocal.reducer";
import { HELPSTEP1, HELPSTEP2, HELPSTEP3, HELPSTEP4 } from "../../assets";
import './css/index.css'

interface FormProps {
  isVisible: boolean;
  handleClose: () => void;
}

export default function HelpPopUp({ isVisible, handleClose }: FormProps): JSX.Element {


  return (
    <Modal size="lg"
      show={isVisible} onHide={handleClose}>

      <Modal.Header style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', margin: '5px' }}>
        <div className="how-it-works-modal-title">
          How it works ?
        </div>
      </Modal.Header >

      <Modal.Body>
        <Row>
          <Col sm={12} lg={3}>
            <div className="help-item">
              <img src={HELPSTEP1} />
              <div>select your shipping category</div>
              <div className="ball pc-only"></div>
            </div>
          </Col>
          <Col sm={12} lg={3}>
            <div className="help-item">
              <img src={HELPSTEP2} />
              <div>choose shipper of your choice</div>
              <div className="ball pc-only"></div>
            </div>
          </Col>
          <Col sm={12} lg={3}>
            <div className="help-item">
              <img src={HELPSTEP3} />
              <div>fill shipping details</div>
              <div className="ball pc-only"></div>
            </div>
          </Col>
          <Col sm={12} lg={3}>
            <div className="help-item">
              <img src={HELPSTEP4} />
              <div>payout and start tracking</div>
              <div className="ball pc-only"></div>
            </div>
          </Col>
        </Row>
        <div className="ball-line pc-only"></div>
      </Modal.Body>
      <Modal.Footer>

        <Button variant="primary" style={{ backgroundColor: '#6E8FE2', borderColor: '#6E8FE2' }} onClick={() => handleClose()}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>

  );

}

