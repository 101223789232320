import { faChevronLeft, faChevronRight, faCircleQuestion, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Divider, Modal } from "@mantine/core";
import { FOOTER_WHATSAPP } from "../../../assets";
import { useState, useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getLoginWhatsappUrlAPI, loginNew, signupNew } from "../../../app/api/auth";
import { loginWithPhone, loginWithPhoneOTP } from "../../../app/api/firebase";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { loginFromDB, setBlocked } from "../../../app/reducer/auth.reducer";
import { getCityName, getStateName } from "../../../app/reducer/hyperlocal.reducer";
import { AppDispatch } from "../../../app/store";
import BackButton from "../../general/BackButton";
import PlacesAutocomplete from "../../maps/PlacesAutocomplete";
import { makeOtplesssFloaterInVisible, makeOtplesssFloaterVisible } from "../../../app/utils/otpless";

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PHONE_REGEX = /^(\+91|\+91\-|0)?[789]\d{9}$/;
const INT_PHONE_REGEX = /^([+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}$/;

const countryPhoneList = [
  {
    countryCode: '+91',
    countryEmoji: '🇮🇳  India (+91)'
  },
  {
    countryCode: '+1',
    countryEmoji: '🇺🇸  USA (+1)'
  },
  {
    countryCode: '+64',
    countryEmoji: '🇳🇿 New Zealand (+64)'
  },
  {
    countryCode: '+61',
    countryEmoji: '🇦🇺 Australia (+61)'
  },
  {
    countryCode: '+60',
    countryEmoji: '🇲🇾  Malaysia (+60)'
  },
  {
    countryCode: '+1',
    countryEmoji: '🇨🇦  Canada (+1)'
  },
  {
    countryCode: '+971',
    countryEmoji: '🇦🇪  UAE (+971)'
  },
  {
    countryCode: '+49',
    countryEmoji: '🇩🇪  Germany (+49)'
  },
  {
    countryCode: '+39',
    countryEmoji: '🇮🇹  Italy (+39)'
  },
  {
    countryCode: '+33',
    countryEmoji: '🇫🇷  France (+33)'
  },
  {
    countryCode: '+31',
    countryEmoji: '🇳🇱  Netherlands (+31)'
  },
  {
    countryCode: '+34',
    countryEmoji: '🇪🇸  Spain (+34)'
  }
]

interface PageProps {
  setActiveStep: Function;
  handleClose: Function;
  isAuthBusy: boolean;
}

type NewUserInputs = {
  email: string
};

interface LoginModalInterface {
  opened: boolean;
  setOpened: Function;
  titleText?: string;
  page?: 0 | 1;
  signInPage?: string | undefined,
  callback?: Function
}

export default function PlacesModal({ opened, setOpened, titleText, page, callback, signInPage }: LoginModalInterface): JSX.Element {

  const [busy, setBusy] = useState<boolean>(false);
  const [signInState, setSignInState] = useState<'number' | 'number-submitted' | 'otp' | 'otp-err' | 'confirmed' | 'new' | 'blocked' | string>('number');

  const [token, setToken] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [value, setvalue] = useState();
  const [countryCode, setCountryCode] = useState<string>('+91');
  const [phoneErr, setPhoneErr] = useState<boolean>(false);

  // const [otpTimestamp, setOtpTimestamp] = useState<number>(0);
  // const [currentTimestamp, setCurrentTimestamp] = useState<number>(0);
  const [otp, setOtp] = useState<string>('');
  const [otpErr, setOtpErr] = useState<boolean>(false);
  const [showOTPdelayMsg, setShowOTPdelayMsg] = useState<boolean>(false);

  const [area, setArea] = useState<string>('');
  const [areaErr, setAreaErr] = useState<boolean>(false);

  const { register, control, setValue, getValues, handleSubmit, setError, formState: { errors } } = useForm<NewUserInputs>();

  const [agree, setAgree] = useState<boolean>(false);
  const [agreeErr, setAgreeErr] = useState<boolean>(false);


  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate()

  const handlePhone = (phone: string) => {
    setPhone(phone)
  }

  const handlevalue = (phone: any) => {
    setvalue(phone)
  }

  useEffect(() => {

    if (signInState === 'number-submitted') {
      setTimeout(() => {

        if (signInState === 'number-submitted') {
          setShowOTPdelayMsg(true)
        }

      }, 5000)
    }

  }, [signInState])


  const submitNumber = () => {


    if (INT_PHONE_REGEX.test(phone)) {
      setBusy(true);
      setSignInState('number-submitted');

      // loginWithPhone(countryCode + phone).then(async () => {
      loginWithPhone("+" + phone).then(async () => {
        setSignInState('otp');
        setBusy(false);
      }
      ).catch((err) => {
        console.log("LOGIN ERR: ", err);
        setBusy(false);
        setPhoneErr(true)
        setSignInState('number');
      }
      )
    } else {
      setPhoneErr(true);
    }

  }

  const submitOTP = () => {

    if (otp.length === 6) {
      setBusy(true);
      loginWithPhoneOTP(otp).then((res) => {
        console.log("USER :", res);
        loginNew({ token: res.stsTokenManager.accessToken }).then(
          async (res) => {
            console.log("DATA", res);
            dispatch(loginFromDB(res.response));
            if (callback) {
              callback()
            }
            setOpened(false)
          }
        ).catch((err) => {
          console.log("DATA", err);
          if (err.error === 403) {
            setBusy(false)
            // setBlocked(true)
            // alert("User forbidden")
            dispatch(setBlocked(true))
            setSignInState('number')
            setOtp('')
            setOpened(false)
            // if(callback){
            //   callback()
            // }
            navigate('/')
          }
          else
            if (err.error === 404) {
              setToken(res.stsTokenManager.accessToken);
              setBusy(false);
              setSignInState('new');
            } else {
              setBusy(false);
            }
        })
      }
      ).catch((err) => {
        console.log("LOGIN ERR: ", err);
        setBusy(false);
        setSignInState('otp-err');
      }
      )
        .finally(() => {
          makeOtplesssFloaterInVisible()
        })
    } else {
      setOtpErr(true);
    }

  }

  useEffect(() => {

    if (otp.length === 6 && busy === false) {
      submitOTP()
    }

  }, [otp])

  const loginWithWhatsapp = async () => {
    makeOtplesssFloaterVisible()
  }

  // useEffect(() => {

  //   if(phone.length === 10 && busy===false){
  //     submitNumber()
  //   }

  // }, [phone])

  const onSubmit: SubmitHandler<NewUserInputs> = (data) => {
    console.log("FORM :", data);

    setBusy(true);

    const userData = {
      token: token,
      // phone:phone,
      phone: phone.slice(2),
      email: data.email
    }

    signupNew(userData).then(async (res) => {
      console.log("Sign in user data", res);
      await dispatch(loginFromDB(res.response));
      if (callback) {
        callback()
      }
      setOpened(false);
    }).catch((err) => {
      console.log(err);
      if (err.error) {
        setError("email", { type: 'custom', message: "Email already exists" })
      }
      setBusy(false)
    })
      .finally(() => {
        makeOtplesssFloaterInVisible()
      })
  };

  return (
    <Modal
      radius={20}
      opened={opened}
      onClose={() => { setOpened(false); makeOtplesssFloaterInVisible() }}
    >
      {(signInState === "number" || signInState === "number-submitted") &&
        <div className="login-modal-body" style={{ padding: '20px' }}>
          <div style={{ padding: '30px 0' }}>
            <span><span style={{ fontSize: '20px', fontWeight: 700 }}>Login</span> or <span style={{ fontSize: '20px', fontWeight: 700 }}>Signup</span></span>
            {/* <span><span style={{ fontSize: '20px', fontWeight: 700 }}>{titleText}</span></span> */}
          </div>


          <div onMouseDown={() => { setPhoneErr(false); }}>
            <PhoneInput
              inputStyle={{ width: '100%' }}
              containerClass={(phoneErr ? "error-border" : "")}
              country={'in'}
              placeholder="Enter your mobile number"
              value={phone}
              onChange={phone => setPhone(phone)}
              countryCodeEditable={false}
              enableSearch={true}
              autocompleteSearch={true}
              inputProps={{
                // name: 'phone',
                required: true,
                autoFocus: true
              }}
            />
          </div>
          {phoneErr && <div className="error-text login-error" style={{ paddingBottom: 15 }}>Please enter a valid phone number.</div>}

          <div style={{ fontSize: '14px', marginTop: '12px' }}>
            By continuing, I agree to <a style={{ color: '#456ED6' }} href="https://www.shipmantra.com/terms-and-conditions">Shipmantra's Terms and conditions</a>
          </div>

          <div className="d-flex justify-content-center align-items-center mt-3">
            <Button id='phone-signin-btn' disabled={busy || signInState !== 'number'}
              className='action-btn'
              style={{
                width: '100%'
              }}
              onClick={() => {
                submitNumber();
              }}>
              {busy ? "Sending OTP" : "Proceed"}
            </Button>
          </div>

          {/* {showOTPdelayMsg &&
            <Alert icon={<FontAwesomeIcon icon={faCircleQuestion} />} title="Not recieving OTP?" color="orange" withCloseButton
              onClose={() => setShowOTPdelayMsg(false)}>
              Try <b>Whatsapp Login</b> if you are having trouble recieving OTP
            </Alert>
          } */}

          {/* <Divider my="xs" label="OR" labelPosition="center" /> */}


          {/* <div className="d-flex justify-content-center align-items-center">
            <Button
              leftIcon={<img src={FOOTER_WHATSAPP} height={15} />}
              color='teal'
              styles={(theme) => ({
                leftIcon: {
                  marginRight: 15,
                },
              })}
              fullWidth
              onClick={() => loginWithWhatsapp()}
            >
              Login using whatsapp
            </Button>
          </div> */}

          {/* <div style={{ fontSize: '14px', marginTop: '25px' }} className='text-center'>
            Having trouble logging in?
          </div>

          <div style={{ fontSize: '14px', marginTop: '4px', color: '#456ED6' }} className='text-center'>
            Get Help
          </div> */}

        </div>
      }

      {(signInState === "otp" || signInState === 'otp-err') &&
        <div className="login-modal-body" style={{ padding: '20px', justifyContent: 'flex-start' }}>

          {/* <label className="modal-form-field">
            <span className="clickable-link" style={{ zoom: 0.9 }} onMouseDown={(e) => { e.preventDefault(); setSignInState('number') }}><FontAwesomeIcon icon={faArrowLeft} />&nbsp;Go Back</span>
          </label> */}
          <div style={{ fontSize: '20px', fontWeight: 600 }}>
            Verify with otp
          </div>

          <div>
            <text style={{ width: '100%', color: '#ABABAB', fontSize: '12px' }}>Sent via SMS to {"+" + phone}:</text>
          </div>

          <div className='d-flex justify-content-center align-items-center' style={{ marginTop: '20px' }} onMouseDown={() => { setOtpErr(false); setSignInState('otp') }}>
            <OtpInput
              value={otp} shouldAutoFocus={true}
              onChange={(otp: string) => setOtp(otp)}
              numInputs={6} isInputNum={true}
              hasErrored={otpErr || signInState === 'otp-err'}
              inputStyle="otp-input"
              errorStyle="input-error"
              containerStyle="otp-wrapper"
            />
          </div>
          <div className="error-text text-center">{(otpErr || signInState === 'otp-err') ? (otpErr ? "Enter 6 digits" : "Incorrect OTP") : " "}</div>
          <Button
            className="action-btn"
            style={{
              marginTop: '20px'
            }}
            onClick={() => submitOTP()} disabled={busy}>
            {busy ? "Submitting OTP" : "Submit OTP"}
          </Button>
        </div>
      }

      {signInState === "confirmed" &&
        <div className="login-modal-body">
          <div className="modal-title magenta-text" style={{ textAlign: 'center' }}>
            Logged In
          </div>
        </div>
      }

      {signInState === "new" &&
        <div className="d-flex flex-column justify-content-center" style={{ padding: '20px', height: '100%' }}>
          <div style={{ fontSize: '24px', cursor: 'pointer', marginBottom: '30px' }}
            onClick={e => setSignInState('number')}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>

          <div style={{ marginTop: '20px', fontWeight: 600 }}>
            Welcome
          </div>
          <div style={{ marginTop: '8px', fontSize: '12px' }}>
            <text>Please enter your EmailID</text>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>

            <label style={{ width: '100%', marginTop: '45px' }}>
              <div>
                <input
                  {...register("email", { required: true, pattern: EMAIL_REGEX })}
                  placeholder="email.address@gmail.com"
                  className={errors.email ? "email-error" : ""}
                  style={{
                    border: '0',
                    width: '100%',
                    borderBottom: '1px solid #6E8FE2'
                  }}
                />
                {errors.email && <div className="error-text login-error" style={{ marginLeft: '0px' }}>{errors.email.message}</div>}
              </div>
            </label>
            {/* <div className="modal-form-field login-field">
              <text style={{ width: '100%' }}>You are a  first time user, please enter your email ID to create account:</text>
            </div> */}

            {/* <label className="modal-form-field login-field">
             <text className="login-label">Phone:</text>
             <div className="modal-form-input login-input">
               <input {...register("phone", { required: true })} disabled={true}/>
             </div>
           </label> */}

            {/* <label className="modal-form-field login-field">
              <div className="modal-form-input login-input">
                <input {...register("email", { required: true, pattern: EMAIL_REGEX })} className={errors.email ? "validation-error" : ""} />
                {errors.email && <div className="error-text login-error">Please enter a valid email.</div>}
              </div>
            </label> */}

            {/* <div className="modal-field" style={{backgroundColor:(agreeErr?'#ff000069':'#fff'), borderRadius:'5px', padding:'5px'}} onMouseDown={()=>setAgreeErr(false)}>
           <Checkbox
             checked={agree}
             onChange={(event) => {setAgree(event.currentTarget.checked);}}
             label={
               <div >
                 I agree the
                 &nbsp;<a href="https:google.co.in" rel="noopener noreferrer nofollow" target="_blank" className="hyperlink">Terms of Use&nbsp;<FontAwesomeIcon icon={faExternalLinkAlt}/></a>&nbsp;and
                 &nbsp;<a href="https:google.co.in" rel="noopener noreferrer nofollow" target="_blank" className="hyperlink">Privacy Policy&nbsp;<FontAwesomeIcon icon={faExternalLinkAlt}/></a>.
               </div>
               }
           />
           </div> */}
            <div className="d-flex justify-content-end align-items-center">
              <Button disabled={busy}
                type="submit"
                style={{ borderRadius: '50%', width: '56px', height: '56px', marginTop: '32px' }}
              >
                <FontAwesomeIcon icon={faChevronRight} />
                {/* {busy ? "Creating Account" : "Create Account"} */}
              </Button>
            </div>
          </form>
        </div>
      }
    </Modal>
  );
}

