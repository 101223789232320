import { KeyboardEventHandler, MouseEventHandler, useState, useEffect, useRef } from "react";
import { Button, Container, Nav, Navbar, NavDropdown, Offcanvas } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { faCommentAlt, faHeadset, faHome, faListAlt, faMapMarkerAlt, faSignOutAlt, faStore, faTruck, faUserCircle } from "@fortawesome/free-solid-svg-icons";

import { BOTTOMNAV_HOME, BOTTOMNAV_OFFER, BOTTOMNAV_ORDER, BOTTOMNAV_SUPPORT, BOTTOMNAV_TRACK, SHIPMANTRA_ICON, SHIPMANTRA_TEXT } from 'assets';
import { getUser, logOut } from "../../app/reducer/auth.reducer";
import { getCityName, setCityBoundsSafe } from "../../app/reducer/hyperlocal.reducer";

import SignInFormMobile from "../popups/SignInMobile";
import ShipperForm from "../popups/ShipperForm";
import ContactUsForm from "../popups/ContactUsForm";
import CitySelectionForm from "../popups/CitySelectionForm";
import ProfileForm from "../popups/ProfileForm";

import { useAppSelector } from "../../app/hooks";

import SelectCityMenu from "../menus/SelectCityMenu";
import { getHyperlocalCitiesState } from "../../app/reducer/general.reducer";

// import home from 'assets/svg/homeIcon.svg'

import { AppDispatch } from "../../app/store";
import { useViewportSize } from "@mantine/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

interface HeaderProps {
    isAuth: boolean;
    onLanding: boolean;
}

function OutsideClickHandler(ref: React.MutableRefObject<any>, outsideClickCallback: Function) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target)) {
                outsideClickCallback();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export default function BottomNav({ isAuth, onLanding }: HeaderProps): JSX.Element {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [showLoginForm, setShowLoginForm] = useState(false);
    const [loginPage, setLoginPage] = useState<0 | 1>(0);
    const [showShipperForm, setShowShipperForm] = useState(false);
    const [showContactForm, setShowContactForm] = useState(false);
    const [showCitySelectionForm, setShowCitySelectionForm] = useState(false);
    const [showProfileForm, setShowProfileForm] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();


    const dispatch = useDispatch<AppDispatch>();
    const user = useAppSelector(getUser);
    const city = useAppSelector(getCityName);
    const hyperlocalCityList = useAppSelector(getHyperlocalCitiesState);
    // const { innerWidth: width } = window;
    const { height, width } = useViewportSize();

    const selfRef = useRef(null);
    OutsideClickHandler(selfRef, () => { setExpanded(false) });

    useEffect(() => {

        if (!isAuth) {
            setShowLoginForm(false);
        }

    }, [isAuth])


    const beautifyText = (name: string): string => {

        let splitStr: any = name.toLowerCase().split("_");

        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }

        return splitStr.join(' ');
    }

    const handleLogOut = () => {

        dispatch(logOut());

    };


    return (
        <div>
            <Navbar
                // className={"header"+((scrollPosition>(onLanding?50:0) || (width<991)?" header-fixed":""))}
                // style={{ display: (location.pathname === '/' || location.pathname === '/orders' || location.pathname === '/support' ? "flex" : "none") }}
                style={{ display: ((location.pathname.includes('book')||location.pathname.includes('agent')) ? 'none' : 'flex') }}
                className="header bottom-nav"
                expand="lg"
                fixed="bottom"
                onKeyDown={(e: any) => e.stopPropagation()}
                onClick={(e: any) => e.stopPropagation()}
                onFocus={(e: any) => e.stopPropagation()}
                onMouseOver={(e: any) => e.stopPropagation()}
                onChange={(e: any) => e.stopPropagation()}
                expanded={expanded}
                ref={selfRef}
            >
                <div className="bottom-nav-btn-wrapper" style={{height:'40px'}}>

                    <div
                        className={"bottom-nav-btn" + ((location.pathname === "/") ? " active" : "")}
                        style={{ color: ((location.pathname === "/") ? "#456ED6" : "#ABABAB") }}
                        onClick={() => {
                            navigate('/');
                            window.location.reload();
                        }}>
                        <img aria-label="bottom nav home button" src={BOTTOMNAV_HOME} style={{ filter: (location.pathname === "/") ? 'invert(25%) sepia(97%) saturate(4013%) hue-rotate(249deg) brightness(93%) contrast(127%)' : 'invert(25%) saturate(4013%) hue-rotate(249deg) brightness(244%) contrast(127%)' }} />
                        <div style={{ fontSize: '12px', marginTop: '4px' }}>
                            HOME
                        </div>
                    </div>
                    {/* <div
                        className={"bottom-nav-btn" + ((location.pathname.includes("orders")) ? " active" : "")}
                        style={{ color: ((location.pathname.includes("offers")) ? "#456ED6" : "#ABABAB") }}
                        onMouseDown={() => {
                            if (isAuth) {
                                navigate('/orders')
                            } else {
                                setLoginPage(0); setShowLoginForm(true)
                            }
                        }}>
                        <img aria-label="bottom nav home button" src={BOTTOMNAV_OFFER} style={{ filter: (location.pathname.includes("offers")) ? 'invert(25%) sepia(97%) saturate(4013%) hue-rotate(249deg) brightness(93%) contrast(127%)' : 'invert(25%) saturate(4013%) hue-rotate(249deg) brightness(244%) contrast(127%)' }} />
                        <div style={{ fontSize: '12px', marginTop: '4px' }}>
                            OFFERS
                        </div>
                    </div> */}

                    <div
                        className={"bottom-nav-btn" + ((location.pathname.includes("support")) ? " active" : "")}
                        style={{ color: ((location.pathname.includes("offers")) ? "#456ED6" : "#ABABAB") }}
                        onClick={() => {
                            window.open('https://wa.me/message/WRL4N6BHGLRJC1', "_blank")
                        }}>
                        {/* <img aria-label="bottom nav home button" src={BOTTOMNAV_SUPPORT} style={{ filter: (location.pathname.includes("support")) ? 'invert(25%) sepia(97%) saturate(4013%) hue-rotate(249deg) brightness(93%) contrast(127%)' : 'invert(25%) saturate(4013%) hue-rotate(249deg) brightness(244%) contrast(127%)' }} /> */}
                        <FontAwesomeIcon icon={faWhatsapp} color='#ABABAB' style={{fontSize:'22px'}} />
                        <div style={{ fontSize: '12px', marginTop: '4px' }}>
                            HELP
                        </div>
                    </div>

                    <div
                        className={"bottom-nav-btn"}
                        // className={"bottom-nav-btn" + ((location.pathname.includes("orders")) ? " active" : "")}
                        style={{ color: ((location.pathname.includes("track")) ? "#456ED6" : "#ABABAB") }}
                        onClick={() => navigate('/track')}>
                        <img aria-label="bottom nav home button" src={BOTTOMNAV_TRACK} style={{ marginBottom:'-6px', filter: (location.pathname.includes("track")) ? 'invert(25%) sepia(97%) saturate(4013%) hue-rotate(216deg) brightness(79%) contrast(127%)' : undefined }} />
                        <div style={{ fontSize: '12px', marginTop: '4px' }}>
                            TRACK
                        </div>
                    </div>


                    {
                        isAuth &&
                        <div
                            className={"bottom-nav-btn" + ((location.pathname.includes("orders")) ? " active" : "")}
                            style={{ color: ((location.pathname.includes("orders")) ? "#456ED6" : "#ABABAB") }}
                            onMouseDown={() => {
                                if (isAuth) {
                                    navigate('/orders')
                                    // navigate('/orders?type=hyperlocal&order=635a128ca325a5f8b38b1826&isOrderNew=true')
                                } else {
                                    setLoginPage(0); setShowLoginForm(true)
                                }
                            }}>
                            <img aria-label="bottom nav home button" src={BOTTOMNAV_ORDER} style={{ marginTop:'-5px', marginBottom:'-2px', filter: (location.pathname.includes("orders")) ? 'invert(25%) sepia(97%) saturate(4013%) hue-rotate(216deg) brightness(79%) contrast(127%)' : undefined }} />
                            {/* <FontAwesomeIcon icon={faListAlt} style={{ fontSize: 20 }} /> */}
                            <div style={{ fontSize: '12px', marginTop: '4px' }}>
                                ORDERS
                            </div>
                        </div>
                    }


                    {/* <div
                        className={"bottom-nav-btn" + ((location.pathname.includes("support")) ? " active" : "")}
                        style={{ color: ((location.pathname.includes("support")) ? "#456ED6" : "#ABABAB") }}
                        onClick={() => {
                            navigate('/support');
                        }}>
                        <img aria-label="bottom nav home button" src={BOTTOMNAV_SUPPORT} style={{ filter: (location.pathname.includes("support")) ? 'invert(25%) sepia(97%) saturate(4013%) hue-rotate(249deg) brightness(93%) contrast(127%)' : 'invert(25%) saturate(4013%) hue-rotate(249deg) brightness(244%) contrast(127%)' }} />
                        <div style={{ fontSize: '12px', marginTop: '4px' }}>
                            SUPPORT
                        </div>
                    </div> */}

                    {/* {
                        isAuth && <div
                            className={"bottom-nav-btn" + ((location.pathname.includes("orders")) ? " active" : "")}
                            style={{ color: ((location.pathname.includes("profile")) ? "#456ED6" : "#ABABAB") }}
                            onMouseDown={() => {
                                if (isAuth) {
                                    navigate('/profile')
                                } else {
                                    setLoginPage(0); setShowLoginForm(true)
                                }
                            }}>
                            <FontAwesomeIcon style={{ fontSize: '20px', color: ((location.pathname.includes("profile")) ? '#6610F2' : '') }} icon={faUserCircle} />
                            <div style={{ fontSize: '12px', marginTop: '4px' }}>
                                PROFILE
                            </div>
                        </div>
                    } */}


                        <div
                            className={"bottom-nav-btn" + ((location.pathname.includes("business")) ? " active" : "")}
                            style={{ color: ((location.pathname.includes("business")) ? "#456ED6" : "#ABABAB") }}
                            onMouseDown={() => {
                                navigate('/business-enquiry')
                            }}>
                            <FontAwesomeIcon style={{ fontSize: '20px', color: ((location.pathname.includes("business")) ? '#6610F2' : '') }} icon={faStore} />
                            <div style={{ fontSize: '12px', marginTop: '4px' }}>
                                B2B
                            </div>
                        </div>


                    {/* {
                    isAuth ?
                        <div
                            className={"bottom-nav-btn" + ((location.pathname.includes("profile")) ? " active" : "")}
                            style={{ color: ((location.pathname.includes("profile")) ? "#456ED6" : "#ABABAB"), fontSize: '12px' }}
                            onMouseDown={() => {
                                if (isAuth) {
                                    navigate('/profile')
                                } else {
                                    setLoginPage(0); setShowLoginForm(true)
                                }
                            }}>
                            <FontAwesomeIcon icon={faUserCircle} style={{ fontSize: 20 }} />
                            Profile
                        </div>
                        :
                        <div
                            className={"bottom-nav-btn" + (showLoginForm ? " active" : "")}
                            style={{ color: ((location.pathname === "/") ? "#456ED6" : "#ABABAB") }}
                            onMouseDown={() => {
                                setLoginPage(0); setShowLoginForm(true)
                            }}>
                            <FontAwesomeIcon icon={faUserCircle} style={{ fontSize: 20 }} />
                            Sign In
                        </div>
                } */}
                </div>

                {!isAuth && showLoginForm && <SignInFormMobile titleText="Sign In" page={loginPage} isVisible={showLoginForm && (!isAuth)} handleClose={() => setShowLoginForm(false)} />}
                <ShipperForm isVisible={showShipperForm} handleClose={() => setShowShipperForm(false)} />
                <ContactUsForm isVisible={showContactForm} handleClose={() => setShowContactForm(false)} />

                {
                    (width > 991) ?
                        <CitySelectionForm isVisible={showCitySelectionForm} handleClose={() => setShowCitySelectionForm(false)} />
                        :
                        <Offcanvas show={showCitySelectionForm} onHide={() => setShowCitySelectionForm(false)} scroll={true} backdrop={true} placement="end">
                            <SelectCityMenu handleClose={() => setShowCitySelectionForm(false)} />
                        </Offcanvas>
                }
{/* 
                {showProfileForm && <ProfileForm isVisible={showProfileForm} handleClose={() => setShowProfileForm(false)} userData={user} />} */}
            </Navbar>
        </div>
    )

}
