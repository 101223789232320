import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';

import { addNewUserAddress, deleteUserAddress, getUserAddress, updateUserAddress } from '../api/auth';

export interface LoginParams {
    identifier: string;
    password: string;
};

export interface SignUpParams {
    username: string;
    email: string;
    password: string;
    contact: string;
};

export interface AuthState {
    fetching: boolean,
    ready: boolean,
    isAuth: boolean;
    user: {
        isAdmin: boolean,
        isBlocked: boolean,
        id: string,
        uid: string,
        username: string,
        email: string,
        phone: string,
        full_name: string,
        address: any[] | [],
        kyc_filename: string,
        default_places: any,
    };
    addresses: Address[]
}

interface Address {
    uid: String,
    type: String,
    label: String,
    details: {
        house?: String,
        building?: String,
        street: String,
        landmark?: String
    },
    metaData: any,
    mapsObject: {
        lat: Number,
        lng: Number,
        address: String,
        pincode?: String,
        city: String,
        state: String,
        country: String
    },
    favourite?: Boolean,
    active?: Boolean
}

const initialState: AuthState = {
    fetching: false,
    isAuth: false,
    ready: false,
    user: {
        isAdmin: false,
        isBlocked: false,
        id: '',
        uid: '',
        username: '',
        email: '',
        phone: '',
        full_name: '',
        address: [],
        kyc_filename: '',
        default_places: undefined,
    },
    addresses: []
}

// const initialState: AuthState = {
//     fetching: false,
//       isAuth: true,
//       user: {
//         uid: '420',
//         username: 'Susmit'  
//       }
// }

export const addNewUserAddressAsync = createAsyncThunk(
    'auth/createAddress',
    async (data: any) => {
        const { reqBody } = data
        const response = await addNewUserAddress({ reqBody });
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const getUserAddressAsync = createAsyncThunk(
    'auth/getAddress',
    async (data: any) => {
        const response = await getUserAddress(data);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const updateUserAddressAsync = createAsyncThunk(
    'auth/updateAddress',
    async (data: any) => {
        const response = await updateUserAddress(data);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const deleteUserAddressAsync = createAsyncThunk(
    'auth/deleteAddress',
    async (data: any) => {
        const response = await deleteUserAddress(data);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginFromDB: (state, action: any) => {
            state.isAuth = true
            state.fetching = false
            const { data } = action.payload;
            // const { user, jwt } = action.payload;
            console.log("LOGIN", action.payload)
            state.user = {
                isAdmin: data.isAdmin ? data.isAdmin : false,
                isBlocked: data.blocked ? data.blocked : false,
                id: data._id,
                uid: data.uid,
                username: data.username ? data.username : (data.email ? data.email : ''),
                email: data.email ? data.email : '',
                phone: data.contact ? data.contact : '',
                full_name: data.full_name ? data.full_name : '',
                address: data.address ? data.address : [],
                kyc_filename: data.kyc_filename ? data.kyc_filename : '',
                default_places: data.default_places ? data.default_places : undefined,
            };
            // localStorage.setItem('shipmantra-authToken', jwt);
            localStorage.setItem('shipmantra-node_user_id', data._id);
            localStorage.setItem('shipmantra-uid', data.uid);
            localStorage.setItem('shipmantra-isAdmin', data.isAdmin ? data.isAdmin : false);
            localStorage.setItem('shipmantra-username', data.username ? data.username : data.email);
            localStorage.setItem('shipmantra-email', data.email);
            localStorage.setItem('shipmantra-phone', data.contact);
            localStorage.setItem('shipmantra-full_name', data.full_name ? data.full_name : '');
            localStorage.setItem('shipmantra-address', data.address ? JSON.stringify(data.address) : JSON.stringify([]));
            localStorage.setItem('shipmantra-kyc_filename', data.kyc_filename ? data.kyc_filename : '');
        },
        loginFromSession: (state, action: any) => {
            console.log("USER", action);
            state.user = action.payload;
            state.isAuth = true;
            state.ready = true;
        },
        updateUser: (state, action) => {
            state.user = action.payload
        },
        saveAddress: (state, action) => {
            state.addresses = action.payload
        },
        beReady: (state) => {
            state.ready = true;
        },
        logOut: (state) => {
            state.user = {
                isAdmin: false,
                isBlocked: false,
                id: '',
                uid: '',
                username: '',
                email: '',
                phone: '',
                full_name: '',
                address: [],
                kyc_filename: '',
                default_places: undefined,
            };
            state.isAuth = false;
            localStorage.removeItem('shipmantra-uid');
            localStorage.removeItem('shipmantra-isAdmin');
            localStorage.removeItem('shipmantra-node_user_id');
            localStorage.removeItem('shipmantra-authToken');
            localStorage.removeItem('shipmantra-username');
            localStorage.removeItem('shipmantra-email');
            localStorage.removeItem('shipmantra-phone');
            localStorage.removeItem('shipmantra-full_name');
            localStorage.removeItem('shipmantra-address');
            localStorage.removeItem('shipmantra-kyc_filename');
        },
        updateProfile: (state, action) => {
            const { email, contact, address, full_name, kyc_filename } = action.payload;
            state.user.email = email;
            state.user.phone = contact;
            state.user.address = address ? address : [];
            state.user.full_name = full_name ? full_name : '';
            state.user.kyc_filename = kyc_filename ? kyc_filename : '';
            localStorage.setItem('shipmantra-email', email);
            localStorage.setItem('shipmantra-phone', contact);
            localStorage.setItem('shipmantra-full_name', full_name ? full_name : '');
            localStorage.setItem('shipmantra-address', address ? JSON.stringify(address) : JSON.stringify([]));
            localStorage.setItem('shipmantra-kyc_filename', kyc_filename ? kyc_filename : '');
        },
        setBlocked: (state, action) => {
            state.user.isBlocked = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addNewUserAddressAsync.pending, (state) => {
                state.fetching = true
            })
            .addCase(addNewUserAddressAsync.fulfilled, (state, action) => {
                state.fetching = false
                const { addressData } = action.payload;
                state.addresses.push(addressData)
                // console.log("LOGIN", action.payload)
                // state.user = {
                //     ...state.user,
                //     address: data.address ? data.address : [],
                // };
                // localStorage.setItem('shipmantra-address', data.address ? JSON.stringify(data.address) : JSON.stringify([]));
            })
            .addCase(addNewUserAddressAsync.rejected, (state) => {
                state.fetching = false
            })

            .addCase(getUserAddressAsync.pending, (state) => {
                state.fetching = true
            })
            .addCase(getUserAddressAsync.fulfilled, (state, action) => {
                state.fetching = false
                console.log(action.payload)
                const { data } = action.payload
                state.addresses = data
                state.user.address = data
            })
            .addCase(getUserAddressAsync.rejected, (state) => {
                state.fetching = false
            })

            .addCase(deleteUserAddressAsync.pending, (state) => {
                state.fetching = true
            })
            .addCase(deleteUserAddressAsync.fulfilled, (state, action) => {
                state.fetching = false
            })
            .addCase(deleteUserAddressAsync.rejected, (state) => {
                state.fetching = false
            })
    }
})

export const { loginFromSession, loginFromDB, updateUser, logOut, beReady, updateProfile, setBlocked, saveAddress } = authSlice.actions;

export const getAuth = (state: RootState) => state.auth.isAuth;
export const getAuthBusy = (state: RootState) => state.auth.fetching;
export const getUser = (state: RootState) => state.auth.user;
export const getUserAddresses = (state: RootState) => state.auth.addresses;
export const getReady = (state: RootState) => state.auth.ready;

export default authSlice.reducer;