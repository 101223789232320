import { Loader, Modal } from '@mantine/core';
import React, { useEffect, useRef, useState } from 'react'
import { cashfreeSandbox } from 'cashfree-dropjs'

interface Props {
    isVisible: boolean | any;
    handleClose: Function;
}

export default function CashfreePayment({ isVisible, handleClose }: Props) {
    let testCashfree = new cashfreeSandbox.Cashfree();
    const [loading, setLoading] = useState(false)
    // const [element, setElement] = useState<HTMLElement | null>(null)
    // const el = document.getElementById("cashfree-form")
    // console.log(el)

    const payment_ref = useRef(null)

    // useEffect(() => {
    //     if (el) {
    //         setElement(el)
    //         console.log(element)
    //     }
    // }, [el])

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            const element = document.getElementById('cashfree-form')
            console.log(element)
            console.log(payment_ref.current)
            if (element) {
                testCashfree.initialiseDropin(element, {
                    orderToken: "qVGMTkAYBS4IIRSYlPq8",
                    onSuccess: function (data: any) {
                        console.log("SUCCESS DATA = ", data)
                        alert("Check console - success")
                    },
                    onFailure: function (data: any) {
                        console.log("FAILURE DATA = ", data)
                        alert("Check console - failure")
                    },
                    components: [
                        "order-details",
                        "card",
                        "netbanking",
                        "app",
                        "upi",
                        "paylater",
                        "credicardemi",
                        "cardlessemi",
                    ],
                    style: {
                        //to be replaced by the desired values
                        "backgroundColor": "#ffffff",
                        "color": "#11385b",
                        "fontFamily": "Lato",
                        "fontSize": "14px",
                        "errorColor": "#ff0000",
                        "theme": "light", //(or dark)
                    },
                })
            };
        }, 5000)
    }, [])

    return (
        <>
            <Modal
                opened={isVisible}
                onClose={() => handleClose()}
                title="Payment"
                zIndex={100000}
            >
                {
                    loading ?
                        <Loader /> :
                        <div
                            ref={payment_ref}
                            id='cashfree-form'>
                        </div>
                }
            </Modal>
        </>
    )
}
