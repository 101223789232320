export const makeOtplesssFloaterInVisible = () => {
    const otplessDiv = document.getElementById("otpless-floating-button") as HTMLElement;
    otplessDiv.style.display = "none";
    otplessDiv.style.setProperty("display", "none", "important");
}

export const makeOtplesssFloaterVisible = () => {
    const otplessDiv = document.getElementById("otpless-floating-button") as HTMLElement;
    otplessDiv.style.display = "flex";
    otplessDiv.style.setProperty("display", "flex", "important");
}
