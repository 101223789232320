import { useState, useEffect, useRef } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useViewportSize, useWindowScroll } from '@mantine/hooks';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faChevronDown, faChevronUp, faList, faLocationCrosshairs, faUser, faSignOutAlt, faUserCircle, faCalculator } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import { SHIPMANTRA_TEXT } from '../../assets';
import { getUser, logOut } from "../../app/reducer/auth.reducer";
import { getCityName, setCityBoundsSafe } from "../../app/reducer/hyperlocal.reducer";

import SignInMobile from "../popups/SignInMobile";
import ShipperForm from "../popups/ShipperForm";
import ContactUsForm from "../popups/ContactUsForm";
import ProfileForm from "../popups/ProfileForm";
import HelpPopUp from '../popups/HelpPopUp'

import { useAppSelector } from "../../app/hooks";

import { getHyperlocalCitiesState } from "../../app/reducer/general.reducer";
import { AppDispatch } from "../../app/store";
import HandOff from "../general/HandOff";
import LoginModal from "../desktop/modals/LoginModal";
import CashfreePayment from "../general/CashfreePayment";
import { HOW_IT_WORKS, SUPPORT_NAV } from "../../assets/icons";
import { Button, Divider, Menu, UnstyledButton } from "@mantine/core";
import CityModal from "../desktop/modals/CityModal";
import { parseCapital } from "../../assets/utis";

interface HeaderProps {
    isAuth: boolean;
    onLanding: boolean;
}

function OutsideClickHandler(ref: React.MutableRefObject<any>, outsideClickCallback: Function) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target)) {
                outsideClickCallback();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export default function Header({ isAuth, onLanding }: HeaderProps): JSX.Element {
    const [expanded, setExpanded] = useState<boolean>(false);

    const [userMenuOpened, setUserMenuOpened] = useState<boolean>(false);
    const [showLoginForm, setShowLoginForm] = useState(false);
    const [loginPage, setLoginPage] = useState<0 | 1>(0);
    const [showShipperForm, setShowShipperForm] = useState(false);
    const [showContactForm, setShowContactForm] = useState(false);
    const [showCitySelectionForm, setShowCitySelectionForm] = useState(false);
    const [showProfileForm, setShowProfileForm] = useState(false);
    const [showHelpPopup, setShowHelpPopup] = useState(false);

    const [openedCityModal, setOpenedCityModal] = useState(false);

    const [signInPage, setSignInPage] = useState<'number' | 'number-submitted' | 'otp' | 'otp-err' | 'confirmed' | 'new' | 'blocked'>('number');

    const [showCashfreePopup, setShowCashfreePopup] = useState<boolean>(false)

    const [showHandOff, setShowHandOff] = useState<boolean>(false);

    const [scrollPosition, scrollTo] = useWindowScroll();

    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const dispatch = useDispatch<AppDispatch>();
    const user = useAppSelector(getUser);
    const city = useAppSelector(getCityName);
    const hyperlocalCityList = useAppSelector(getHyperlocalCitiesState);
    // const { innerWidth: width } = window;
    const { height, width } = useViewportSize();
    const [scroll] = useWindowScroll();

    const selfRef = useRef(null);
    OutsideClickHandler(selfRef, () => { setExpanded(false) });

    const beautifyText = (name: string): string => {

        let splitStr: any = name.toLowerCase().split("_");

        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }

        return splitStr.join(' ');
    }

    useEffect(() => {

        const handOffShown = localStorage.getItem("shipmantra-handOffShown");

        if (!handOffShown && (searchParams.get('redirect') === null)) {
            setShowHandOff(true);
        }

    }, [])


    useEffect(() => {

        if (isAuth)
            setShowLoginForm(false);

        const cityName = localStorage.getItem('hyperlocalCity');

        if (cityName !== undefined && (city !== '')) {
            for (let i = 0; i < hyperlocalCityList.length; i++) {

                if (cityName === hyperlocalCityList[i].cityString) {

                    let city = hyperlocalCityList[i].cityString;
                    let cityBounds = {
                        sw: hyperlocalCityList[i].sw,
                        ne: hyperlocalCityList[i].ne
                    }

                    dispatch(setCityBoundsSafe({ city, cityBounds }));

                }

            }
        }

    }, [city]);

    const handleLogOut = () => {
        const res = window.confirm("Are you sure you want to log out ?")
        if (res) {
            dispatch(logOut())
            let url = new URL(window.location.href);
            url.search = ''
            window.location.replace(url.toString())
        }
    };

    return (
        <Navbar className={"header"+(scroll.y>0?" header-fixed":'')} sticky="top"
            onKeyDown={(e: any) => e.stopPropagation()}
            onClick={(e: any) => e.stopPropagation()}
            onFocus={(e: any) => e.stopPropagation()}
            onMouseOver={(e: any) => e.stopPropagation()}
            onChange={(e: any) => e.stopPropagation()}
            ref={selfRef}
            style={{ display: location.pathname.includes('/agent')? 'none' : 'flex' }}
        >
            <Container fluid>
                <Navbar.Brand href="#home">
                    <img
                        // src={(width<991)?SHIPMANTRA_TEXT.default:SHIPMANTRA_ICON}
                        src={SHIPMANTRA_TEXT}
                        // width="30"
                        height="23"
                        alt="Shipmantra logo"
                        style={{
                            cursor: 'pointer',
                            // paddingLeft: (width > 991) ? '0px' : '15px'
                        }}
                        onMouseDown={e => {
                            setExpanded(false);
                            navigate('/');
                            window.location.reload();
                        }
                        }
                    />
                </Navbar.Brand>
                <Nav className="ms-auto" style={{alignItems:'center', gap:'10px'}}>
                    {/* <Nav.Link>
                        {
                            city &&
                            <span
                                className="magenta-text"
                                style={{ zoom: 0.8, }}
                                onMouseDown={e => { setShowCitySelectionForm(true); }}>
                                <FontAwesomeIcon icon={faMapMarkerAlt} />&nbsp;{beautifyText(city ? city : "select_city")}
                            </span>
                        }
                    </Nav.Link> */}
                    {/* <Nav.Link>
                        <div onClick={() => { setShowCashfreePopup(true); }}>
                            <FontAwesomeIcon icon={faCashRegister} />
                        </div>
                    </Nav.Link> */}
                    {width>990 &&<Nav.Link>
                        <UnstyledButton
                            // compact
                            className='slider-cta-btn compact'
                            onClick={() => navigate('/apsrtc/personal')}
                            // color='violet'
                        >
                            APSRTC Booking
                        </UnstyledButton>
                    </Nav.Link>}

                    {width>990 &&<Nav.Link>
                        <UnstyledButton
                            // compact
                            className='slider-cta-btn compact'
                            onClick={()=>window.open('https://business.shipmantra.com', '_blank')}
                            // color='violet'
                        >
                            Business Login
                        </UnstyledButton>
                    </Nav.Link>}

                    {width>990 &&<Nav.Link>
                        <UnstyledButton
                            // compact
                            className='slider-cta-btn compact'
                            onClick={() => navigate('/become-our-agent')}
                            // color='violet'
                        >
                            Become our partner
                        </UnstyledButton>
                    </Nav.Link>}


                    {/* {width>990 &&<Nav.Link>
                        <UnstyledButton
                            // compact
                            style={{
                                color:'#1b1b1b', fontFamily:'Inter', fontSize: '16px', fontWeight: 600
                            }}
                            onClick={() => { setShowHelpPopup(true) }}
                            // color='violet'
                        >
                            Quick Guide
                        </UnstyledButton>
                    </Nav.Link>} */}

                    {width>990 && (location.pathname !== '/track') &&<Nav.Link>
                        <UnstyledButton
                            className='slider-cta-btn compact'
                            // compact
                            onClick={() => navigate('/track')}
                            // color='violet'
                        >
                            Track Order
                        </UnstyledButton>
                    </Nav.Link>}

                    {/* {width>990 &&<Nav.Link>
                        <UnstyledButton
                            className='slider-cta-btn compact'
                            // compact
                            onClick={() => navigate('/track')}
                            // color='violet'
                        >
                            Rate Calculator&nbsp;&nbsp;<FontAwesomeIcon icon={faCalculator}/>
                        </UnstyledButton>
                    </Nav.Link>} */}

                    {/* {width>990 && (location.pathname !== '/track') &&<Nav.Link>
                        <UnstyledButton
                            className='slider-cta-btn compact'
                            // compact
                            onClick={() => setOpenedCityModal(true)}
                            // color='violet'
                        >
                            <FontAwesomeIcon icon={faLocationCrosshairs}/>&nbsp;&nbsp;{city?parseCapital(city):"Select Your Location"}&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faChevronDown}/>
                        </UnstyledButton>
                    </Nav.Link>} */}

                    {/* <Nav.Link>
                        <Menu shadow="lg"
                            control={
                                <div
                                >
                                    <img src={SUPPORT_NAV} height='23' style={{ cursor: 'pointer' }} alt="support" />
                                </div>}
                            onClose={()=>setUserMenuOpened(false)} onOpen={()=>setUserMenuOpened(true)}
                        >
                            <Menu.Label>Customer Support</Menu.Label>
                            <Menu.Item
                            onClick={() => {
                                window.open('https://wa.me/message/WRL4N6BHGLRJC1', "_blank")
                            }} icon={<FontAwesomeIcon icon={faWhatsapp} />}>Whatsapp</Menu.Item>
                            <Menu.Item onClick={() => { navigate('/support') }} icon={<FontAwesomeIcon icon={faUserCircle} />}>Chat</Menu.Item>
                        </Menu>
                    </Nav.Link> */}

                    {/* <Nav.Link>
                        <div onClick={() => { setShowHelpPopup(true) }}>
                            <img src={HOW_IT_WORKS} height='23' style={{ cursor: 'pointer' }} alt="How it works" />
                        </div>
                    </Nav.Link> */}

                    <Nav.Link>
                        {
                            isAuth ?
                                <Menu shadow="lg"
                                    control={<div>{(width > 990) ?
                                    // <span style={{ color: '#744ed5', fontSize: '12px', fontWeight: 400, textDecoration: 'underline' }}>{user.phone}</span>
                                    <div className='slider-cta-btn compact' style={{height:'38px', fontSize:'14px', padding:0, width:'38px', borderRadius:'50%', border: '1px solid black', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                        <FontAwesomeIcon icon={faUser}/>
                                    </div>
                                    :<FontAwesomeIcon icon={userMenuOpened ? faChevronUp : ((width > 990) ? faChevronDown : faBars)} />}
                                    </div>
                                    }
                                    onClose={() => setUserMenuOpened(false)} onOpen={() => setUserMenuOpened(true)}
                                >
                                    <Menu.Item onClick={() => { navigate('/orders') }} icon={<FontAwesomeIcon icon={faList} />}>My Orders</Menu.Item>
                                    <Menu.Item onClick={() => { navigate('/profile') }} icon={<FontAwesomeIcon icon={faUserCircle} />}>My Profile</Menu.Item>
                                    <Divider />
                                    <Menu.Item onClick={() => { handleLogOut() }} color="red" icon={<FontAwesomeIcon icon={faSignOutAlt} />}>Sign Out</Menu.Item>
                                </Menu>
                                // <div onClick={() => { handleLogOut() }} style={{fontSize:'18px'}}>
                                //     <FontAwesomeIcon icon={faSignOutAlt} />
                                // </div>
                                :
                                <div onClick={() => { setLoginPage(0); setExpanded(false); setShowLoginForm(true); }} style={{ fontSize: '18px' }}>
                                    <FontAwesomeIcon icon={faUserCircle} />
                                </div>
                        }
                    </Nav.Link>
                </Nav>
            </Container>

            {!isAuth && showLoginForm && (width < 991) && <SignInMobile titleText="Sign In" page={loginPage} isVisible={showLoginForm && (!isAuth)} handleClose={() => setShowLoginForm(false)} />}
            {!isAuth && showLoginForm && (width > 990) && <LoginModal opened={showLoginForm && (!isAuth)} setOpened={setShowLoginForm} titleText="Sign In" />}
            <ShipperForm isVisible={showShipperForm} handleClose={() => setShowShipperForm(false)} />
            <ContactUsForm isVisible={showContactForm} handleClose={() => setShowContactForm(false)} />
            <HelpPopUp isVisible={showHelpPopup} handleClose={() => setShowHelpPopup(false)} />
            {/* {
                (width > 991) ?
                    <CitySelectionForm isVisible={showCitySelectionForm} handleClose={() => setShowCitySelectionForm(false)} />
                    :
                    <Offcanvas show={showCitySelectionForm} onHide={() => setShowCitySelectionForm(false)} scroll={true} backdrop={true} placement="end">
                        <SelectCityMenu handleClose={() => setShowCitySelectionForm(false)} />
                    </Offcanvas>
            } */}

            {openedCityModal && <CityModal opened={openedCityModal} setOpened={setOpenedCityModal} />}
            {showCashfreePopup && <CashfreePayment isVisible={showCashfreePopup} handleClose={() => setShowCashfreePopup(false)} />}
            {showProfileForm && <ProfileForm isVisible={showProfileForm} handleClose={() => setShowProfileForm(false)} userData={user} />}
            {/* {showHandOff && <HandOff handleClose={() => { setShowLoginForm(true); localStorage.setItem("shipmantra-handOffShown", "done"); setShowHandOff(false) }} />} */}
        </Navbar >
    )

}
