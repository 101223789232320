import { SearchData } from "../../reducer/panindia/pet.reducer";
import axios, { AxiosResponse } from "axios";
import { checkLogin } from "../firebase";

const nodeBaseUrl = process.env.REACT_APP_NODE_BASE_URL
const localhostUrl = 'http://localhost:8000'

export function searchPetShipper(formData: any) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/panindia-shippers/search/pet-logistics`,
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: formData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export async function createRequestPet(orderData: any) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/panindia-orders/pet-logistics/create`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            },
            data: orderData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}