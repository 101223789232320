import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAgentCustomerAPI, getCustomerStoreDataAPI, createCustomerStoreDataAPI, getStoreAPI, getAgentCustomerWalletAPI, searchAgentShippersAPI, searchPayload, getAllAgentOrdersAPI } from 'app/api/agent/store';
import { RootState } from 'app/store';
import { signout } from 'app/api/firebase';

export interface GeneralState {
    fetchingStore: boolean,
    busy: boolean,
    storeData: any | undefined,
    customerStoreData: any | undefined,
    selectedShipper: undefined | any,
    wantDoorPickup: boolean,
    wantDoorDelivery: boolean,
    pickupMethod: string,
    deliveryMethod: string,
    shipperResult: any[],
    storeOrders: any[],
    customerTransactions: any[],
    transPages: number,
    customerWalletBalance: number | undefined,
    customerWalletCredit: number | undefined,
    storeOrderDetails: any,
    totalPages: number,
    customer: any,
    isAuth: boolean
}

const initialState: GeneralState = {
    fetchingStore: false,
    busy: false,
    storeData: undefined,
    customerStoreData: undefined,
    selectedShipper: undefined,
    wantDoorPickup: true,
    wantDoorDelivery: true,
    pickupMethod: '',
    deliveryMethod: '',
    shipperResult: [],
    storeOrders: [],
    customerTransactions: [],
    transPages: 1,
    customerWalletBalance: undefined,
    customerWalletCredit: undefined,
    storeOrderDetails: undefined,
    totalPages: 1,
    customer: undefined,
    isAuth: false
}

export const getStoreCustomerDataAction = createAsyncThunk(
    'agentStore/getStoreCustomerData',
    async (agentUid: string, { rejectWithValue }) => {
        let response: any

        await getCustomerStoreDataAPI(agentUid)
            .then((res) => {
                console.log("res", res);
                response = res.response;
            })
            .catch((err) => {
                console.log("err", err);
                response = rejectWithValue(err)
            });
        // The value we return becomes the `fulfilled` action payload
        return response;
    }
);

export const createStoreCustomerDataAction = createAsyncThunk(
    'agentStore/createStoreCustomerData',
    async (data: { agentUid: string, phone: string }, { rejectWithValue }) => {
        let response: any

        const { agentUid, phone } = data
        await createCustomerStoreDataAPI(agentUid, phone)
            .then((res) => {
                console.log("res", res);
                response = res.response;
            })
            .catch((err) => {
                console.log("err", err);
                response = rejectWithValue(err)
            });
        // The value we return becomes the `fulfilled` action payload
        return response;
    }
);

export const getAgentCustomerAction = createAsyncThunk(
    'agentStore/getAgentCustomer',
    async (payload: { token?: string }, { rejectWithValue }) => {
        let response: any

        await getAgentCustomerAPI(payload.token)
            .then((res) => {
                console.log("res", res);
                response = res.response;
            })
            .catch((err) => {
                console.log("err", err);
                response = rejectWithValue(err)
            });
        // The value we return becomes the `fulfilled` action payload
        return response;
    }
);

export const getAgentCustomerWalletAction = createAsyncThunk(
    'agentStore/getAgentCustomerWallet',
    async (payload: { token?: string }, { rejectWithValue }) => {
        let response: any

        await getAgentCustomerWalletAPI(payload.token)
            .then((res) => {
                console.log("res", res);
                response = res.response.data;
            })
            .catch((err) => {
                console.log("err", err);
                response = rejectWithValue(err)
            });
        // The value we return becomes the `fulfilled` action payload
        return response;
    }
);

export const getAgentStoreAction = createAsyncThunk(
    'agentStore/getStore',
    async (agentID: string) => {
        const response = await getStoreAPI(agentID);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const searchAgentShippersAction = createAsyncThunk(
    'agentStore/searchShipper',
    async (payload: searchPayload) => {
        const response = await searchAgentShippersAPI(payload);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const getAllAgentOrdersAction = createAsyncThunk(
    'orders/getAllAgentOrders',
    async (params: { custOrgId: string, orderStatus?: string, orderType?: string, page?: number, limit?: number, searchString?: string, append?: boolean }) => {
        const response = await getAllAgentOrdersAPI(params);
        // The value we return becomes the `fulfilled` action payload
        return { ...response.response, append: params.append };
    }
);

export const agentStoreSlice = createSlice({
    name: 'agentStore',
    initialState,
    reducers: {
        setSelectedShipper: (state, action) => {
            state.selectedShipper = action.payload
        },
        setDoorPickup: (state, action) => {
            state.wantDoorPickup = action.payload
        },
        setDoorDelivery: (state, action) => {
            state.wantDoorDelivery = action.payload
        },
        setPickupMethod: (state, action) => {
            state.pickupMethod = action.payload
        },
        setDeliveryMethod: (state, action) => {
            state.deliveryMethod = action.payload
        },
        logOutAgent: (state) => {
            state.customer = undefined;
            state.isAuth = false;
            signout();
        },
        updateStoreCustomerData: (state, action) => {
            state.customer = {
                ...state.customer,
                ...action.payload
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getStoreCustomerDataAction.pending, (state) => {
                state.busy = true
            })
            .addCase(getStoreCustomerDataAction.fulfilled, (state, action) => {
                state.busy = false
                state.customer = action.payload.data.data
                state.customerStoreData = action.payload.data.data.agentCustomerData;
                state.customerTransactions = action.payload.data.data.agentCustomerData.transactions.results;
                state.customerWalletBalance = action.payload.data.data.agentCustomerData.walletBalance;
                state.customerWalletCredit = action.payload.data.data.agentCustomerData.walletCreditLimit;
                state.isAuth = true;
            })
            .addCase(getStoreCustomerDataAction.rejected, (state) => {
                state.busy = false
            })

            .addCase(createStoreCustomerDataAction.pending, (state) => {
                state.busy = true
            })
            .addCase(createStoreCustomerDataAction.fulfilled, (state, action) => {
                state.busy = false
                state.customerStoreData = action.payload.data.data.agentCustomerData;
                state.customerTransactions = action.payload.data.data.transactions?.results;
                state.customerWalletBalance = action.payload.data.data.walletBalance;
                state.customerWalletCredit = action.payload.data.data.walletCreditLimit;
                state.isAuth = true;
            })
            .addCase(createStoreCustomerDataAction.rejected, (state) => {
                state.busy = false
            })

            .addCase(getAgentCustomerAction.pending, (state) => {
                state.busy = true
            })
            .addCase(getAgentCustomerAction.fulfilled, (state, action) => {
                state.busy = false
                state.customer = action.payload.data.user;
                state.isAuth = true;
            })
            .addCase(getAgentCustomerAction.rejected, (state) => {
                state.busy = false
            })

            .addCase(getAgentCustomerWalletAction.pending, (state) => {
                state.busy = true
            })
            .addCase(getAgentCustomerWalletAction.fulfilled, (state, action) => {
                state.busy = false
                state.customerTransactions = action.payload.data.result.results;
                state.customerWalletBalance = action.payload.data.walletBalance;
                state.transPages = action.payload.data.result.totalPages
                state.isAuth = true;
            })
            .addCase(getAgentCustomerWalletAction.rejected, (state) => {
                state.busy = false
            })

            .addCase(getAgentStoreAction.pending, (state) => {
                state.fetchingStore = true
            })
            .addCase(getAgentStoreAction.fulfilled, (state, action) => {
                state.fetchingStore = false
                state.storeData = action.payload.data.data;
            })
            .addCase(getAgentStoreAction.rejected, (state) => {
                state.fetchingStore = false
            })

            .addCase(searchAgentShippersAction.pending, (state) => {
                state.busy = true
                state.shipperResult = []
            })
            .addCase(searchAgentShippersAction.fulfilled, (state, action) => {
                state.busy = false
                state.shipperResult = action.payload.data.data;
            })
            .addCase(searchAgentShippersAction.rejected, (state) => {
                state.busy = false
                state.shipperResult = []
            })

            .addCase(getAllAgentOrdersAction.pending, (state) => {
                state.busy = true
                state.storeOrders = [];
            })
            .addCase(getAllAgentOrdersAction.fulfilled, (state, action) => {
                state.busy = false
                const { results, totalPages } = action.payload.data;
                if (action.payload.append) {
                    state.storeOrders = [...state.storeOrders, ...results];
                } else {
                    state.storeOrders = results;

                }
                state.totalPages = totalPages || 1
            })
            .addCase(getAllAgentOrdersAction.rejected, (state) => {
                state.busy = false
            })
    }
})

export const { logOutAgent, setSelectedShipper, setDoorPickup, setDoorDelivery, setPickupMethod, setDeliveryMethod, updateStoreCustomerData } = agentStoreSlice.actions

export const getAgentStoreAuth = (state: RootState) => state.agentStore.isAuth;
export const getAgentStore = (state: RootState) => state.agentStore.storeData;
export const getAgentCustomer = (state: RootState) => state.agentStore.customer;
export const getAgentCustomerData = (state: RootState) => state.agentStore.customerStoreData;

export const getAgentCustomerTransactions = (state: RootState) => state.agentStore.customerTransactions;
export const getAgentCustomerTransactionsPages = (state: RootState) => state.agentStore.transPages;
export const getAgentCustomerWallet = (state: RootState) => state.agentStore.customerWalletBalance;
export const getAgentCustomerCredit = (state: RootState) => state.agentStore.customerWalletCredit;

export const getAgentBusy = (state: RootState) => state.agentStore.busy;
export const getAgentShipperResult = (state: RootState) => state.agentStore.shipperResult;
export const getSelectedShipper = (state: RootState) => state.agentStore.selectedShipper;

export const getAllAgentOrdersState = (state: RootState) => state.agentStore.storeOrders;
export const getAllAgentOrdersTotalPages = (state: RootState) => state.agentStore.totalPages;

export const getDoorPickup = (state: RootState) => state.agentStore.wantDoorPickup;
export const getDoorDelivery = (state: RootState) => state.agentStore.wantDoorDelivery;

export const getPickupMethod = (state: RootState) => state.agentStore.pickupMethod;
export const getDeliveryMethod = (state: RootState) => state.agentStore.deliveryMethod;

export default agentStoreSlice.reducer;