/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";

import { setCityBounds } from "../../app/reducer/hyperlocal.reducer";

import { INDIANFLAG } from '../../assets';


import './menu.css';
import { useAppSelector } from "../../app/hooks";
import { getHyperlocalCitiesState } from "../../app/reducer/general.reducer";
import { AppDispatch } from "../../app/store";

interface MainMenuProps {
    handleClose: Function
}

interface CityBound {
    cityString: string;
    cityText: string;
    sw: {
        lat: number;
        lng: number;
    };
    ne: {
        lat: number;
        lng: number;
    };
}

export default function SelectCityMenu({ handleClose }: MainMenuProps): JSX.Element {
    const dispatch = useDispatch<AppDispatch>();
    const hyperlocalCityList = useAppSelector(getHyperlocalCitiesState);

    const [name, setName] = useState<string>('');
    const [cityArr, setCityArr] = useState<any>(hyperlocalCityList);

    const selectCity = (city: string, cityBounds: { sw: { lat: number, lng: number }, ne: { lat: number, lng: number } }) => {

        dispatch(setCityBounds({ city, cityBounds }));
        handleClose();

    }

    const cityName = localStorage.getItem('hyperlocalCity');

    useEffect(() => {

        if (name) {
            let tempcityArr: any[] = [];
            for (let i = 0; i < hyperlocalCityList.length; i++) {

                if (hyperlocalCityList[i].cityText.toLowerCase().includes(name.toLowerCase()))
                    tempcityArr.push(hyperlocalCityList[i])

            }
            setCityArr(tempcityArr);
        } else {
            setCityArr(hyperlocalCityList);
        }

    }, [name, hyperlocalCityList]);


    return (
        <div className="side-menu-wrapper">
            <div className="side-menu-header primary-text magenta-text"
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
                onMouseDown={e => { e.preventDefault(); handleClose(); }}>
                <FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Select your City &nbsp;
                <span style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <img src={INDIANFLAG} style={{ height: '10px' }} />
                    &nbsp;IN
                </span>
            </div>
            <hr />
            <div className="side-menu-content primary-text" style={{ alignItems: 'center' }}>
                <input placeholder="Search city" value={name} onChange={e => setName(e.target.value)} />
            </div>
            <hr />
            <div className="side-menu-content primary-text menu-links" style={{ height: 'auto', overflowY: 'scroll' }}>
                {

                    cityArr.map((el: CityBound) => {
                        const { cityString, cityText, sw, ne } = el;

                        return (
                            <a href="javascript:void(0)"
                                onMouseDown={e => {
                                    { selectCity(cityString, { sw, ne }) }
                                }}>
                                {cityText}
                            </a>
                        )
                    })

                }
            </div>
            {/* {isAuth &&
          <div className="side-menu-content primary-text" style={{alignItems:'center'}}>
           
          </div>
          } */}
        </div>
    );

}