export const SHIPMANTRA_TEXT = require('./svg/shipmantra_text_logo.svg');
export const SHIPMANTRA_ICON = require('./svg/shipmantra_icon_logo.svg');
export const SHIPMANTRA_TEXT_WHITE = require('./svg/shipmantra_text_logo_white.svg');

export const AGENT_BANNER_1 = require('./png/agent-banner1.png');
export const AGENT_BANNER_2 = require('./png/agent-banner2.png');
export const AGENT_BANNER_3 = require('./png/agent-banner3.png');

export const GOLINTU_LOGO = require('./svg/golintu_logo.svg');

export const COMING_SOON = require('./svg/comingsoon.svg');

export const VEHICLES_BG = require('./svg/vehicles.svg');

export const HYPERLOCAL_LANDING = require('./svg/hyperlocal-01.svg');
export const INTERCITY_LANDING = require('./svg/domestic-01.svg');
export const INTERNATIONAL_LANDING = require('./svg/international-01.svg');

// export const COMPARE = require('./svg/compare.svg');
export const MONEY = require('./svg/money.svg');
export const SHIPPER = require('./svg/shipper.svg');
export const EASY = require('./svg/easy.svg');

// export const SAMEDAY = require('./svg/sameday.svg');
// export const COLDCHAIN = require('./svg/coldchain.svg');
// export const LIVESTOCK = require('./svg/pet.svg');
export const CRITICAL = require('./svg/critical.svg');
// export const PACKERMOVER = require('./svg/packers.svg');

export const INTPERSONAL = require('./png/family.png');
export const INTCOMMERCIAL = require('./png/shop.png');

export const UPS = require('./logo/ups.svg');
export const DHL = require('./logo/dhl-express.svg');
// export const WEFAST = require('./logo/wefast.svg');
export const BORZO = require('./logo/borzo.svg');
export const SM_EXPRESS = require('./logo/sm-express.png');
export const CHOTABETA = require('./logo/chotabeta.svg');
// export const ARAMEX = require('./logo/aramex.svg');
export const BLUEDART = require('./logo/bluedart.svg');
export const DELHIVERY = require('./logo/delhivery.svg');
export const DUNZO = require('./logo/dunzo.svg');
export const QWQER = require('./logo/qwqer.svg');
export const PORTER = require('./logo/porter.svg');
export const GATI = require('./logo/gati.svg');
export const TELYPORT = require('./logo/telyport.svg');
export const OMNIEXPRESS = require('./logo/omni-express.png');
export const LETSTRANSPORT = require('./logo/lets-transport.png');
export const ECOMEXPRESS = require('./logo/ecom-express.png');
export const PERFECTLOGISTICS = require('./logo/perfect-logistics.png');
export const TRACKON = require('./logo/trackon.svg');
export const CARRY_MY_PET = require('./logo/carry_my_pet.png')
export const WORLD_FIRST = require('./logo/world_first.svg')
export const FASTWAY = require('./logo/fastway.png')
export const SHADOW_FAX = require('./logo/shadow-fax.svg')
export const APSRTC = require('./logo/apsrtc.png')
export const INDIA_POST = require('./logo/India_Post_Logo_With_Text.svg')
export const XPRESS_BEES = require('./logo/Xpressbees .png')

export const NASSCOM = require('./logo/nasscom.png')
export const THUB = require('./logo/t-hub.png')
export const ANDHRAINNOVATION = require('./logo/andhra-innovation.png')

export const INDIANFLAG = require('./svg/india_flag.svg');
export const UKFLAG = require('./svg/UK_flag.png');
export const USAFLAG = require('./svg/USA_flag.svg');

export const TRUCK = require('./png/truck.png');
export const THREEWHEELER = require('./png/three_wheeler.png');
export const RENTTRUCK = require('./png/truck_rental.png');
export const SCOOTER = require('./svg/delivery_scooter.svg');
export const PETS = require('./png/pets.png');
export const JEWELRY = require('./png/jewelry.png');
export const FREEZE = require('./png/air-conditioner.png');
export const ICON24HRS = require('./svg/24hrs.svg');

export const NOTFOUND = require('./svg/not_found.svg');

export const HELPSTEP1 = require('./svg/help1.svg');
export const HELPSTEP2 = require('./svg/help2.svg');
export const HELPSTEP3 = require('./svg/help3.svg');
export const HELPSTEP4 = require('./svg/help4.svg');

export const SHOPLADY = require('./svg/shop_lady.svg');

export const LOTTIE_SEARCH = require('./lottie/search-animation.json');


export const USER_PROFILE = require('./png/reviewUserProfile.png')
export const DOWNLOAD_LOGO = require('./logo/download.jpeg')

export const RIGHT_ARROW = require('./svg/right_arrow.svg')
export const MAP_MARKER = require('./svg/map_marker.svg')

export const EXPORT = require('./svg/export.svg');
export const HYPERLOCAL_ONDEMAND = require('./svg/hyperlocal_ondemand.svg')
export const RENT_VEHICLE = require('./svg/rent_vehicle.svg')
export const IMPORT = require('./svg/import.svg')
export const PAN_INDIA = require('./svg/pan_india.svg')

export const COLDCHAIN = require('./svg/ourServices/coldchain.svg')
export const EXPORT_SERVICES = require('./svg/ourServices/export.svg')
export const FOUR_WHEELER = require('./svg/ourServices/four_wheeler.svg')
export const IMPORT_SERVICES = require('./svg/ourServices/import.svg')
export const LIVESTOCK = require('./svg/ourServices/livestock.svg')
export const PET = require('./svg/ourServices/pet.svg')
export const PACKERSMOVER = require('./svg/ourServices/packers_movers.svg')
export const SAMEDAY = require('./svg/ourServices/same_day.svg')
export const TWO_WHEELER = require('./svg/ourServices/two_wheeler.svg')


export const COMPARE = require('./svg/whyShipmantra/compare.svg')
export const EASY_BOOKING = require('./svg/whyShipmantra/easy_booking.svg')
export const LOW_PRICE = require('./svg/whyShipmantra/low_price.svg')
export const SHIPPING_OPTION = require('./svg/whyShipmantra/shipping_options.svg')
export const START_QUOTE = require('./svg/startQuote.svg')
export const END_QUOTE = require('./svg/endQuote.svg')


export const DISCOUNT_BADGE = require('./svg/discount_badge.svg')
export const HANDOFFSLIDE1 = require('./svg/handoff_slide1.svg')
export const HANDOFFSLIDE2 = require('./svg/handoff_slide2.svg')
export const HANDOFFSLIDE3 = require('./svg/handoff_slide3.svg')

export const ONDEMAND_THREEWHEELER = require('./svg/ondemand_twoWheeler.svg')
export const ONDEMAND_FOURW_TATA_ACE = require('./svg/ondemand_fourWTataAce.svg')
export const ONDEMAND_FOURW_BOLERO = require('./svg/ondemand_fourWBolero.svg')

export const PLAYSTORE_INSTALL = require('./svg/playstore_install_btn.svg')
export const APPLESTORE_INSTALL = require('./svg/applestore_install_btn.svg')

export const HOME_SLIDER1_MAN = require('./svg/home-slider1-man.svg')
export const HOME_SLIDER1_GLOBE = require('./svg/home-slider1-globe.svg')
export const HOME_SLIDER1_INDIA = require('./svg/home-slider1-india.svg')
export const HOME_SLIDER1_PHONE = require('./svg/home-slider1-phone.svg')

export const HOME_SLIDER2_RENTAL = require('./svg/home-slider2-rental.svg')
export const HOME_SLIDER2_TRAFFIC = require('./svg/home-slider2-traffic.svg')
export const HOME_SLIDER2_INSTANT = require('./svg/home-slider2-instant.svg')

export const HOME_SLIDER3_COUNTRY = require('./svg/home-slider3-country.svg')
export const HOME_SLIDER3_PET = require('./svg/home-slider3-pet.svg')
export const HOME_SLIDER3_BAGGAGE = require('./svg/home-slider3-baggage.svg')
export const HOME_SLIDER3_SAMEDAY = require('./svg/home-slider3-sameday.svg')

export const HOME_SLIDER4_IMPORT = require('./svg/home-slider4-import.svg')
export const HOME_SLIDER4_EXPORT = require('./svg/home-slider4-export.svg')
export const HOME_SLIDER4_PACKAGE = require('./svg/home-slider4-packaging.svg')
export const HOME_SLIDER4_PICKUP = require('./svg/home-slider4-pickup.svg')

export const HOME_SLIDER5_LB_FLOWER = require('./svg/slider5_left_bottom_flower.svg')
export const HOME_SLIDER5_TR_FLOWER = require('./svg/slider5_top_right_flower.svg')
export const HOME_SLIDER5_APSRTC_LOGO = require('./svg/slider5_apsrtc_logo.svg')
export const HOME_SLIDER5_BOOK = require('./svg/slider5_book.svg')

export const HOME_ACTION_RENTAL = require('./svg/home-action-rental.svg')
export const HOME_ACTION_TWOWHEELER = require('./svg/home-action-two-wheeler.svg')
export const HOME_ACTION_THREEWHELER = require('./svg/home-action-three-wheeler.svg')
export const HOME_ACTION_FOURWHEELER = require('./svg/home-action-four-wheeler.svg')
export const HOME_ACTION_SAMEDAY = require('./svg/home-action-same-day.svg')
export const HOME_ACTION_PET = require('./svg/home-action-pet.svg')
export const HOME_ACTION_COLDCHAIN = require('./svg/home-action-cold.svg')
export const HOME_ACTION_PACKER = require('./svg/home-action-packer.svg')
export const HOME_ACTION_IMPORT = require('./svg/home-action-import.svg')
export const HOME_ACTION_EXPORT = require('./svg/home-action-export.svg')
export const HOME_ACTION_USA = require('./svg/home-action-usa.svg')

export const SEARCH_RENTAL = require('./svg/search-rental.svg')
export const SEARCH_TWO = require('./svg/search-two.svg')
export const SEARCH_THREE = require('./svg/search-three.svg')
export const SEARCH_FOUR = require('./svg/search-four.svg')
export const SEARCH_EXPRESS = require('./svg/search-express.svg')
export const SEARCH_PET = require('./svg/search-pet.svg')
export const SEARCH_PACKER = require('./svg/search-packer.svg')
export const SEARCH_COLD = require('./svg/search-cold.svg')

export const SEARCH_IMPORT = require('./svg/search-import.svg')
export const SEARCH_EXPORT = require('./svg/search-export.svg')


export const PET_LICENCED = require('./svg/pet_license.svg')
export const PET_MEDICAL = require('./svg/pet_medical.svg')
export const PET_VACCINATED = require('./svg/pet_vaccines.svg')

export const CARD_BUSINESS = require('./svg/card-business.svg')
export const CARD_PERSONAL = require('./svg/card-personal.svg')

export const DESKTOP_COMINGSOON = require('./svg/desktop-comingsoon.svg')

export const FOOTER_IG = require('./svg/instagram-footer-social.svg');
export const FOOTER_TWITTER = require('./svg/twitter-footer-social.svg');
export const FOOTER_PINTEREST = require('./svg/pinterest-footer-social.svg');
export const FOOTER_LINKEDIN = require('./svg/linkedin-footer-social.svg');
export const FOOTER_FB = require('./svg/facebook-footer-social.svg');
export const FOOTER_WHATSAPP = require('./svg/whatsapp-footer-social.svg');

export const BOTTOMNAV_HOME = require('./svg/homeIcon.svg');
export const BOTTOMNAV_TRACK = require('./svg/trackingIcon.svg');
export const BOTTOMNAV_ORDER = require('./svg/ordersIcon.svg');
export const BOTTOMNAV_OFFER = require('./svg/offersIcon.svg');
export const BOTTOMNAV_SUPPORT = require('./svg/supportIcon.svg');
export const BOTTOMNAV_PROFILE = require('./svg/profileIcon.png');

export const FRAGILE_ICON = require('./icons/fragile.png')

export const WHATSAPP_QR_CODE = require('./png/WhatsappQRCode.jpeg')

// export const BOTTOMNAV_TRACK = require('./svg/bottomnav_tracking.svg');

export const BG_TRACKING = require('./svg/bg_order.svg');
export const BG_TRACKING_SUPPORT = require('./svg/bg_order_support.svg');

export const ORDER_DETAIL_HANDOVER = require('./svg/orderDetails_deliver.svg')
export const ORDER_DETAIL_TIME = require('./svg/orderDetails_find.svg')
export const ORDER_DETAIL_BIKE = require('./svg/orderDetails_bike.svg')
export const ORDER_DETAIL_BIKE_INV = require('./svg/orderDetails_bikeinv.svg')
export const ORDER_DETAIL_CHECKLIST = require('./svg/orderDetails_checklist.svg')
export const ORDER_DETAIL_TRUCKCLOCK = require('./svg/orderDetails_truckclock.svg')
export const ORDER_DETAIL_TRUCKLOAD = require('./svg/orderDetails_truckload.svg')
export const ORDER_DETAIL_PACKAGE = require('./svg/orderDetails_package.svg')
export const ORDER_DETAIL_OFFICER = require('./svg/orderDetails_officer.svg')
export const ORDER_DETAIL_TRANSIT = require('./svg/orderDetails_transit.svg')
export const ORDER_DETAIL_GREENTRUCK = require('./svg/orderDetails_greentruck.svg')
export const ORDER_DETAIL_TRUCKTRACK = require('./svg/orderDetails_trucktrack.svg')
export const ORDER_DETAIL_WAREHOUSE = require('./svg/orderDetails_warehouse.svg')
export const ORDER_DETAIL_CANCELLED = require('./svg/orderDetails_cancelled.svg')
export const PACKAGE_CHECK = require('./svg/package_check.svg');

export const B2B_UNIFIED_DASH = require('./svg/unified_dashboard.svg');
export const B2B_UNIFIED_DASH_PNG = require('./png/dashboard.png');
export const B2B_UNIFIED_DASH_HOME = require('./png/dashboard-home.png');

export const SHOP_CLOSED = require('./svg/shop_closed.svg')
export const AGENT_INDIA = require('./svg/agent_india.svg')
export const AGENT_INTERNATIONAL = require('./svg/agent_international.svg')

export const AGENT_SELF = require('./svg/self_agent.svg')
export const AGENT_PRIORITY = require('./svg/priority_agent.svg')
export const AGENT_ONPREMISES = require('./svg/onPremise_agent.svg')

export const AGENT_LANDING = require('./svg/agent-landing.svg')

export const NEW_LANDING1 = require('./svg/new-landing1.svg')
export const NEW_LANDING2 = require('./svg/new-landing2.svg')
export const NEW_LANDING3 = require('./svg/new-landing3.svg')

export const LW_BG = require('./svg/landing-background.svg')
export const INT_LW = require('./svg/int_lw.svg')
export const LOCAL_LW = require('./svg/local_lw.svg')
export const PAN_LW = require('./svg/pan_lw.svg')
export const APSRTC_LW = require('./svg/apsrtc_lw.svg')

// export const AGENT_SELF = require('./svg/self_agent.svg')
// export const AGENT_PRIORITY = require('./svg/priority_agent.svg')
// export const AGENT_ONPREMISES = require('./svg/onPremise_agent.svg')

export const RUPEE_SYMBOL = require('./svg/ruppee_symbol.png');
export const RED_LOCATION_MARKER = require('./svg/red_location_marker_ball.png');
export const GREEN_LOCATION_MARKER = require('./svg/green_location_marker_ball.png');

export const DOUBLE_ARROW = require('./svg/double_arrow.svg')
export const CARTON_BOX = require('./svg/carton-box.svg')
export const BOX_ICON = require('./svg/box-icon.svg')

export const AGENT_SERVICE1 = require('./svg/agent-service-1.svg')
export const AGENT_SERVICE2 = require('./svg/agent-service-2.svg')
export const AGENT_SERVICE3 = require('./svg/agent-service-3.svg')
export const AGENT_SERVICE4 = require('./svg/agent-service-4.svg')

export const AGENT_USP1 = require('./svg/agent-usp-1.svg')


export const AGENT_RATE = require('./svg/agent-rate.svg')

export const POWEREDBY_SHIPMANTRA = require('./logo/poweredby-shipmantra.svg')

