import { faArrowRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect, useRef } from "react";
import { Button, Carousel, Modal } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { getAuth, getUser, updateUser } from "../../app/reducer/auth.reducer";
import { getExportCitiesState, getExportCountriesState, getHyperlocalCitiesState, getRentalCitiesList, getRentalVehicleList } from "../../app/reducer/general.reducer";
import { setCityBounds } from "../../app/reducer/hyperlocal.reducer";
import { AppDispatch } from "../../app/store";

import { INDIANFLAG, RIGHT_ARROW } from '../../assets';
import HyperlocalNoOptionsForm from "./HyperlocalNoOptionsForm";
import InternationalNoOptionsForm from "./InternationalNoOptionsForm";
import PanIndiaNoOptionsForm from "./PanIndiaNoOptionsForm";

interface CityBound {
  cityString: string;
  cityText: string;
  sw: {
    lat: number;
    lng: number;
  };
  ne: {
    lat: number;
    lng: number;
  };
  state: string
}

interface FormProps {
  isVisible: boolean;
  handleClose: (() => void);
  serviceType?: number,
  onSelectCallback?: Function,
  redirectUrl?: string,
  titleText?: string,
}

export default function CitySelectionForm({ isVisible, handleClose, serviceType, onSelectCallback, redirectUrl, titleText }: FormProps): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate()

  const [openedNoOption, setOpenedNoOption] = useState<boolean>(false);

  const hyperlocalCityList = useAppSelector(getHyperlocalCitiesState);
  const rentalCityList = useAppSelector(getRentalCitiesList)
  const exportCitiesList = useAppSelector(getExportCitiesState)
  const exportCountries = useAppSelector(getExportCountriesState)

  const userData = useAppSelector(getUser)

  const [cityArr, setCityArr] = useState<any>(hyperlocalCityList);


  const tempMetroCity = [
    {
      cityString: "mumbai",
      cityText: "Mumbai"
    },
    {
      cityString: "bangalore",
      cityText: "Bangalore"
    },
    {
      cityString: "chennai",
      cityText: "Chennai"
    },
    {
      cityString: "pune",
      cityText: "Pune"
    }
  ]

  const selectCity = (city: string, cityBounds: { sw: { lat: number, lng: number }, ne: { lat: number, lng: number } }, state: string) => {

    dispatch(setCityBounds({ city, cityBounds }));
    localStorage.setItem('shipmantra-state', state)
    handleClose();
    if (redirectUrl) {
      navigate(redirectUrl)
    }
  }

  useEffect(() => {
    if (serviceType === 1)
      setCityArr(hyperlocalCityList)
    else if (serviceType === 1.1)
      setCityArr(rentalCityList)
    else if (serviceType === 2)
      setCityArr(tempMetroCity)
    else if (serviceType === 3)
      setCityArr(exportCitiesList)
    else if (serviceType === 4)
      setCityArr(exportCountries)
    else
      setCityArr([{ cityText: "Mumbai", state: "Maharashtra" }])

  }, [hyperlocalCityList, rentalCityList, serviceType]);


  return (
    <Modal size="lg"
      dialogClassName="city-modal"
      show={isVisible} onHide={handleClose}>

      <Modal.Body style={{ padding: '0px', overflowY: 'scroll' }}>
        <div className="d-flex flex-column justify-content-center">
          <div className="d-flex" style={{ marginTop: '28px', padding: '0 16px' }}>
            <div onClick={e => handleClose()}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
            <div className="flex-grow-1 text-center align-self-center" style={{ fontSize: '14px' }}>{titleText ? titleText : ""}</div>
          </div>

          <div className="d-flex justify-content-center align-items-center" style={{ marginTop: '24px', fontWeight: 600, textTransform: 'uppercase' }}>
            Select a city to proceed
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', marginTop: '14px' }}>
            {
              cityArr.map((el: CityBound) => {
                const { cityString, cityText, sw, ne, state } = el;

                return (
                  <div key={cityString} style={{ cursor: 'pointer', padding: '6px 30px', borderBottom: '1px solid #F7F5FA' }}
                    onClick={e => selectCity(cityString, { sw, ne }, state)}
                  >
                    <div className="d-flex justify-content-between align-items-center" >
                      <div className="d-flex flex-column">
                        <div style={{ fontWeight: 700, fontSize: '16px', lineHeight: '20px', color: '#1B1B1B' }}>{cityText}</div>
                        <div style={{ fontSize: '12px', color: '#ABABAB' }}>{state}</div>
                      </div>
                      <div
                        style={{
                          width: '32px',
                          height: '16px',
                          background: '#3F3F3F',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '8px',
                        }}
                      >
                        <img src={RIGHT_ARROW} style={{ objectFit: 'contain' }} alt="" />
                      </div>
                    </div>
                  </div>
                )
              })
            }
            <div style={{ width: '100%', textAlign: 'center', color: '#744ed5', fontSize: '12px', fontWeight: 600, textDecoration: 'underline', cursor: 'pointer', marginTop: '10px', paddingBottom: '10px', marginBottom: '10px' }} onClick={() => {
              // setOpenedNoOption(true)
              navigate('/c2c-request-form')
            }}>
              Your city not in the list?
            </div>
          </div>

        </div>
      </Modal.Body>
      {serviceType && serviceType < 2 && <HyperlocalNoOptionsForm opened={openedNoOption} handleClose={() => setOpenedNoOption(false)} />}
      {serviceType === 2 && <PanIndiaNoOptionsForm opened={openedNoOption} handleClose={() => setOpenedNoOption(false)} />}
      {serviceType === 3 && <InternationalNoOptionsForm opened={openedNoOption} handleClose={() => setOpenedNoOption(false)} />}
    </Modal>

  );

}

