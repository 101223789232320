import { String } from "aws-sdk/clients/acm";
import axios, { AxiosResponse } from "axios";
import { checkLogin } from "./firebase";

let baseUrl = process.env.REACT_APP_ENQUIRY_BASE_URL;
let token = process.env.REACT_APP_BEARER_TOKEN;
const localhostURL = 'http://localhost:8000'
const nodeBaseUrl = process.env.REACT_APP_NODE_BASE_URL;


// axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = baseUrl;
// // axios.defaults.headers.post['Access-Control-Allow-Credentials'] = true;
// axios.defaults.headers.post['Authorization'] = `Bearer ${token}`;

export interface IntSearchData {
    city: string,
    country: string,
    weight: number,
    itemType?: string,
    shipmentPurpose?: string
}

export interface createIntOrderData {
    isSuperAdmin: boolean,
    shipperID: string,
    user: string,
    email: string,
    city: string,
    destination: string,
    weight: string,
    bookedAt: any,
    start: {
        contact: {
            name: string,
            phone: string,
            email?: string,
        },
        address: {
            fromGoogle: {
                address: string,
                lat: number,
                lng: number,
                pincode: string
            },
            flatno?: string | undefined,
            building?: string | undefined,
        }
    },
    end: {
        contact: {
            name: string,
            phone: string
        },
        address: {
            address: {
                flatno?: string | undefined,
                building?: string | undefined,
                fromGoogle: {
                    address: string,
                    lat: number,
                    lng: number,
                    pincode: string
                }
            },
            city: string,
            zip: string
        }
    },
    items: {
        name: string, qty: number, other: string
    }[],
    pkg?: {
        l: number, b: number, h: number
    },
    order_type: string,
    coupon?: string,
    shipmentType?: string
}

export interface createExportRequestData {
    shipperID: string,
    user: string,
    email: string,
    city: string,
    destination: string,
    weight: string,
    start: {
        contact: {
            name: string,
            phone: string
        },
        address: {
            flatno: string,
            building: string,
            fromGoogle: {
                lat: number,
                lng: number,
                addres: string,
                pincode: string
            }
        }
    },

    end: {
        contact: {
            name: string,
            phone: string
        },
        address: {
            city: string,
            zip: string,
            address: {
                flatno: string,
                building: string,
                fromGoogle: {
                    lat: number,
                    lng: number,
                    addres: string,
                    pincode: string
                }
            }
        }
    },
    items: {}[],
    pkg?: any[]
}

export interface createImportOrderData {
    destination: {
        fromGoogle: {
            lat: number,
            lng: number,
            address: string,
            pincode: number
        },
        address: string,
        city: string,
        contact: {
            name: string,
            phone: string,
            altPhone: string
        }
    },
    importCountry: string,
    shipper: string,
    user: string,
    email: string
}

export interface ConfirmIntOrderData {
    orderID: string,
    payment_status: string,
    transactionID: string,
    state: string
}

export interface UpdateData {
    shipperID: string,
    destination: string,
    weight: number | any,
    coupon?: string;
    user: string;
    shipmentType?: string
}

export function searchIntShipper(formData: IntSearchData) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/international-shippers/search/${formData.city}&${formData.weight}&${formData.country}&${formData.itemType}`,
            method: "GET",
            headers: {
                Accept: "application/json"
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export async function updateQuote(formData: UpdateData) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    // const { shipperID, destination, weight } = formData
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/international-shippers/resetOrder`,
            method: "POST",
            headers: {
                Accept: 'application/json',
                Authorization: "Bearer " + (authToken ? authToken : '')
            },
            data: formData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}


export function searchImportShipper() {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/international-shippers/search/imports`,
            method: "GET",
            headers: {
                Accept: "application/json"
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export async function createIntOrder(formData: createIntOrderData) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/internationalorders/initPayment`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            },
            data: formData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export async function createExportRequest(formData: createExportRequestData) {
    console.log(formData);
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/internationalorders/createRequest`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            },
            data: formData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export async function createImportOrder(formData: createImportOrderData) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/importrequests/create`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            },
            data: formData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

//not required
export function confirmIntOrder(formData: ConfirmIntOrderData) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/internationalorders/createOrder`,
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: formData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}