import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllOrders, getOrderDetails, getAllIntOrders, getOrderIntDetails, getAllRentalOrders, getOrderRentalDetails, getAllAPSRTCOrders } from '../../app/api/order';
import { getExpressOrdersForUser, getSingleExpressOrderForUser } from '../../app/api/panindia/expressdelivery';
import { getAllApsrtcOrders, getApsrtcOrderDetails } from '../../app/api/agent/apsrtc';
import { RootState } from '../../app/store';

export interface BookingsState {
    fetching: boolean;
    fetchingDetails: boolean,
    hyperlocalOrders: any[];
    internationalOrders: any[];
    rentalOrders: any[];
    expressOrders: any[];
    apsrtcOrders: any[];
    hyperlocalOrderDetail: any | undefined;
    internationalOrderDetail: any | undefined;
    rentalOrderDetail: any | undefined;
    expressOrderDetail: any | undefined;
    apsrtcOrderDetail: any | undefined;
}

const initialState: BookingsState = {
    fetching: false,
    fetchingDetails: false,
    hyperlocalOrders: [],
    internationalOrders: [],
    rentalOrders: [],
    expressOrders: [],
    apsrtcOrders: [],
    hyperlocalOrderDetail: undefined,
    internationalOrderDetail: undefined,
    rentalOrderDetail: undefined,
    expressOrderDetail: undefined,
    apsrtcOrderDetail: undefined
}

export const getAllHyperlocalOrdersAsync = createAsyncThunk(
    'bookings/GetAllHyperlocalOrders',
    async (searchQuery: { user: string, page?: number, limit?: number }) => {
        const response = await getAllOrders(searchQuery);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const getHyperlocalOrderDetailsAsync = createAsyncThunk(
    'bookings/GetHyperlocalOrderDetails',
    async (orderID: string) => {
        const response = await getOrderDetails(orderID);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const getAllAPSRTCOrdersAsync = createAsyncThunk(
    'bookings/GetAllAPSRTCOrders',
    async (searchQuery: { page?: number, limit?: number }) => {
        const response = await getAllApsrtcOrders(searchQuery);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const getApsrtcOrderDetailsAsync = createAsyncThunk(
    'bookings/GetApsrtcOrderDetails',
    async (orderID: string) => {
        const response = await getApsrtcOrderDetails(orderID);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const getAllIntOrdersAsync = createAsyncThunk(
    'bookings/GetAllInternationalOrders',
    async (searchQuery: { user: string, page?: number, limit?: number }) => {
        const response = await getAllIntOrders(searchQuery);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const getIntOrderDetailsAsync = createAsyncThunk(
    'bookings/GetOrderInternationalDetails',
    async (orderID: string) => {
        const response = await getOrderIntDetails(orderID);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const getAllRentalOrdersAsync = createAsyncThunk(
    'bookings/GetAllRentalOrders',
    async (searchQuery: { uid: string, page?: number, limit?: number }) => {
        const response = await getAllRentalOrders(searchQuery);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const getRentalOrderDetailsAsync = createAsyncThunk(
    'bookings/GetOrderRentalDetails',
    async (orderID: string) => {
        const response = await getOrderRentalDetails(orderID);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const getExpressOrderForUserAsync = createAsyncThunk(
    '/order/getExpressOrders',
    async (data: any) => {
        const response = await getExpressOrdersForUser(data);
        console.log(response);
        return response.response
    }
)

export const getSingleExpressOrderForUserAsync = createAsyncThunk(
    '/order/getSingleExpressOrder',
    async (id: string) => {
        const response = await getSingleExpressOrderForUser(id);
        console.log(response);
        return response.response
    }
)

export const bookingSlice = createSlice({
    name: 'bookings',
    initialState,
    reducers: {
        updateCancelledOrder: (state, action) => {
            let tempOrders = state.apsrtcOrders
            const { orderData } = action.payload;
            for (let i = 0; i < tempOrders.length; i++) {
                const order = tempOrders[i];
                if (order.sm_orderid === orderData.sm_orderid) {
                    tempOrders[i] = orderData;
                    break
                }
            }
            state.apsrtcOrders = tempOrders
            state.apsrtcOrderDetail = orderData
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllHyperlocalOrdersAsync.pending, (state) => {
                state.fetching = true;
            })
            .addCase(getAllHyperlocalOrdersAsync.fulfilled, (state, action) => {
                state.fetching = false;
                state.hyperlocalOrders = action.payload.data.data.results;
            })
            .addCase(getAllHyperlocalOrdersAsync.rejected, (state) => {
                state.fetching = false
                state.hyperlocalOrders = []
            })

            .addCase(getHyperlocalOrderDetailsAsync.pending, (state) => {
                state.fetchingDetails = true;
            })
            .addCase(getHyperlocalOrderDetailsAsync.fulfilled, (state, action) => {
                state.fetchingDetails = false;
                state.hyperlocalOrderDetail = action.payload.data;
            })
            .addCase(getHyperlocalOrderDetailsAsync.rejected, (state) => {
                state.hyperlocalOrderDetail = undefined
                state.fetchingDetails = false;
            })

            .addCase(getAllAPSRTCOrdersAsync.pending, (state) => {
                state.fetching = true;
            })
            .addCase(getAllAPSRTCOrdersAsync.fulfilled, (state, action) => {
                state.fetching = false;
                state.apsrtcOrders = action.payload.data.data.results;
            })
            .addCase(getAllAPSRTCOrdersAsync.rejected, (state) => {
                state.fetching = false
                state.hyperlocalOrders = []
            })

            .addCase(getApsrtcOrderDetailsAsync.pending, (state) => {
                state.fetchingDetails = true;
            })
            .addCase(getApsrtcOrderDetailsAsync.fulfilled, (state, action) => {
                state.fetchingDetails = false;
                console.log(action.payload.data)
                state.apsrtcOrderDetail = action.payload.data;
            })
            .addCase(getApsrtcOrderDetailsAsync.rejected, (state) => {
                state.hyperlocalOrderDetail = undefined
                state.fetchingDetails = false;
            })

            .addCase(getAllIntOrdersAsync.pending, (state) => {
                state.fetching = true;
            })
            .addCase(getAllIntOrdersAsync.fulfilled, (state, action) => {
                state.fetching = false;
                state.internationalOrders = action.payload.data.data;
            })
            .addCase(getAllIntOrdersAsync.rejected, (state) => {
                state.fetching = false
                state.internationalOrders = []
            })

            .addCase(getIntOrderDetailsAsync.pending, (state) => {
                state.fetchingDetails = true;
            })
            .addCase(getIntOrderDetailsAsync.fulfilled, (state, action) => {
                state.fetchingDetails = false;
                state.internationalOrderDetail = action.payload.data.data;
            })
            .addCase(getIntOrderDetailsAsync.rejected, (state) => {
                state.internationalOrderDetail = undefined
                state.fetchingDetails = false;
            })

            .addCase(getAllRentalOrdersAsync.pending, (state) => {
                state.fetching = true;
            })
            .addCase(getAllRentalOrdersAsync.fulfilled, (state, action) => {
                state.fetching = false;
                state.rentalOrders = action.payload.data.data;
            })
            .addCase(getAllRentalOrdersAsync.rejected, (state) => {
                state.fetching = false
                state.rentalOrders = []
            })

            .addCase(getRentalOrderDetailsAsync.pending, (state) => {
                state.fetchingDetails = true;
            })
            .addCase(getRentalOrderDetailsAsync.fulfilled, (state, action) => {
                state.fetchingDetails = false;
                console.log(action.payload.data);
                state.rentalOrderDetail = action.payload.data.data;
            })
            .addCase(getRentalOrderDetailsAsync.rejected, (state) => {
                state.rentalOrderDetail = undefined
                state.fetchingDetails = false;
            })

            .addCase(getExpressOrderForUserAsync.pending, (state) => {
                state.fetching = true;
            })
            .addCase(getExpressOrderForUserAsync.fulfilled, (state, action) => {
                state.fetching = false;
                state.expressOrders = action.payload.data.data
            })
            .addCase(getExpressOrderForUserAsync.rejected, (state) => {
                state.fetching = false
            })

            .addCase(getSingleExpressOrderForUserAsync.pending, (state) => {
                state.fetchingDetails = true;
            })
            .addCase(getSingleExpressOrderForUserAsync.fulfilled, (state, action) => {
                state.fetchingDetails = false;
                state.expressOrderDetail = action.payload.data.data
            })
            .addCase(getSingleExpressOrderForUserAsync.rejected, (state) => {
                state.fetchingDetails = false
            })

    }
})

export const { updateCancelledOrder } = bookingSlice.actions

export const getFetching = (state: RootState) => state.orders.fetching;
export const getFetchingDetails = (state: RootState) => state.orders.fetchingDetails;

export const getHyperlocalOrdersStore = (state: RootState) => state.orders.hyperlocalOrders;
export const getHyperlocalOrderDetailsStore = (state: RootState) => state.orders.hyperlocalOrderDetail;

export const getRentalOrdersStore = (state: RootState) => state.orders.rentalOrders;
export const getRentalOrderDetailsStore = (state: RootState) => state.orders.rentalOrderDetail;


export const getIntOrdersStore = (state: RootState) => state.orders.internationalOrders;
export const getIntOrdersDetailsStore = (state: RootState) => state.orders.internationalOrderDetail;


export const getExpressOrdersStore = (state: RootState) => state.orders.expressOrders;
export const getExpressOrdersDetailsStore = (state: RootState) => state.orders.expressOrderDetail;

export const getApsrtcOrdersStore = (state: RootState) => state.orders.apsrtcOrders
export const getApsrtcOrdersDetailsStore = (state: RootState) => state.orders.apsrtcOrderDetail;

export default bookingSlice.reducer;