import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";

import { sendEnquiry } from "../../app/api/enquiry"

interface FormProps{
    isVisible: boolean;
    handleClose: (() => void);
}

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PHONE_REGEX = /^(\+91|\+91\-|0)?[789]\d{9}$/;

export const platforms = [
  "Shopify",
  "Magento Commerce",
  "BigCommerce",
  "Elementor",
  "Pixpa",
  "3DCart",
  "WooCommerce",
  "Salesforce Commerce Cloud",
  "Squarespace",
  "Yo!Kart",
  "Volusion",
  "Prestashop",
  "Wix",
  "Quick eSelling",
  "MyDukaan",
  "Others"
]

const approxOrders = [
  "10-100",
  "100-500",
  "500-1000",
  "Above 1000"
]


export default function ShipperForm({ isVisible, handleClose }: FormProps): JSX.Element {
  const [status, setStatus] = useState<0 | 1 | 2 | 3 | 4>(0); //0: idle, 1:sending, 2:success, 3:error, 4:already exists
  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = data => {
    setStatus(1);
    sendEnquiry(data).then(res => {
      setStatus(2)
    }
    ).catch(err => {
      console.log("ERR", err)
      if (err.error === 406) {
        setStatus(4)
      } else {
        setStatus(3)
      }
    }
    )
  };

  type Inputs = {
    company: string,
    // address: string,
    name: string,
    phone: string,
    email: string,
    platform: string,
    approxOrders: string,
    website?: string
  };

  return (
    <Modal show={isVisible} onHide={handleClose}>

      {((status === 0) || (status === 1) || (status === 3)) && <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="modal-title magenta-text">
            Let's grow together.
          </div>

          <label className="modal-form-field">
            <text className="required">Brand Name:</text>
            <div className="modal-form-input">
              <input {...register("company", { required: true })} className={errors.company ? "validation-error" : ""} />
              {errors.company && <div className="error-text">Please enter company name.</div>}
            </div>
          </label>
          {/* <label className="modal-form-field">
            <text className="required">Registered Address:</text>
            <div className="modal-form-input">
              <textarea {...register("address", { required: true })} className={errors.address ? "validation-error" : ""} />
              {errors.address && <div className="error-text">Please enter your registered company address.</div>}
            </div>
          </label> */}
          <label className="modal-form-field">
            <text className="required">Platform:</text>
            <div className="modal-form-input">
              <select {...register("platform", { required: true })} className={errors.platform ? "validation-error" : ""}>
                <option value="">Select</option>
                {
                  platforms.map((pfm, index) => {
                    return (
                      <option value={pfm} key={index}>{pfm}</option>
                    )
                  })
                }
              </select>
              {errors.platform && <div className="error-text">Please select one option.</div>}
            </div>
          </label>
          <label className="modal-form-field">
            <text className="required">Approx no of deliveries:</text>
            <div className="modal-form-input">
              <select {...register("approxOrders", { required: true })} className={errors.approxOrders ? "validation-error" : ""}>
                <option value="">Select</option>
                {
                  approxOrders.map((order, index) => {
                    return (
                      <option value={order} key={index}>{order}</option>
                    )
                  })
                }
              </select>
              {errors.approxOrders && <div className="error-text">Please select one option.</div>}
            </div>
          </label>
          <label className="modal-form-field">
            <text className="required">Contact Name:</text>
            <div className="modal-form-input">
              <input {...register("name", { required: true })} className={errors.name ? "validation-error" : ""} />
              {errors.name && <div className="error-text">Please enter name of contact person.</div>}
            </div>
          </label>
          <label className="modal-form-field">
            <text className="required">Phone Number:</text>
            <div className="modal-form-input">
              <input {...register("phone", { required: true, pattern: PHONE_REGEX })} className={errors.phone ? "validation-error" : ""} />
              {errors.phone && <div className="error-text">Please enter a valid phone number.</div>}
            </div>
          </label>
          <label className="modal-form-field">
            <text className="required">Email:</text>
            <div className="modal-form-input">
              <input {...register("email", { required: true, pattern: EMAIL_REGEX })} className={errors.email ? "validation-error" : ""} />
              {errors.email && <div className="error-text">Please enter a valid email address.</div>}
            </div>
          </label>
          <label className="modal-form-field">
            <text>Website link:</text>
            <div className="modal-form-input">
              <input {...register("website")} className={errors.website ? "validation-error" : ""} />
            </div>
          </label>
          {/* <form> */}
          {/* <label className="modal-form-field">
                  <text className="required">Company Name:</text>
                  <input
                      aria-label="Company Name"
                      name="company"
                      value={company} onChange={(e) => setCompany(e.target.value)}
                  />
              </label> */}
          {/* </form> */}
          {status === 3 &&
            <div className="error-text" style={{ textAlign: 'center' }}>Something went wrong. Please try again.</div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Close
          </Button>
          {status === 1 ?
            <Button variant="primary" onClick={(e) => e.preventDefault()}
              style={{ width: 80, display: 'flex', flexDirection: 'row', justifyContent: 'center', cursor: 'default' }}
            >
              <div className="loader" />
            </Button>
            :
            <input className="btn btn-primary" type="submit" style={{ width: 80 }} />
          }
        </Modal.Footer>
      </form>}
      {/* {status===2&& */}

      {status === 2 &&
        <Modal.Body>
          <div className="modal-title magenta-text">
            Your response has been recieved!
          </div>
          <label className="modal-form-field">
            <text style={{ width: '100%' }}>We will get in touch with you soon.</text>
          </label>
        </Modal.Body>
      }
      {status === 4 &&
        <Modal.Body>
          <div className="modal-title magenta-text">
            It looks like you have already submitted your details
          </div>
          <label className="modal-form-field">
            <text style={{ width: '100%' }}>We will get in touch with you soon.</text>
          </label>
        </Modal.Body>
      }
      {((status === 2) || (status === 4)) &&
        <Modal.Footer>
          <Button variant="success" onClick={() => handleClose()}>
            OK
          </Button>
        </Modal.Footer>
      }

      {/* } */}


    </Modal>

  );

}