import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import authReducer from './reducer/auth.reducer';
import generalReducer from './reducer/general.reducer';
import hyperlocalReducer from './reducer/hyperlocal.reducer';
import internationalReducer from './reducer/international.reducer';
import orderReducer from './reducer/orders.reducer';
import expressDeliveryReducer from './reducer/panindia/expressdelivery.reducer'
import petReducer from './reducer/panindia/pet.reducer'
import panIndiaReducer from './reducer/panindia/panindia.reducer'
import apsrtcReducer from './reducer/apsrtc.reducer';

import agentStoreRedcuer from './reducer/agent-store/store.reducer';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    general: generalReducer,
    hyperlocal: hyperlocalReducer,
    international: internationalReducer,
    orders: orderReducer,
    expressDelivery: expressDeliveryReducer,
    pet: petReducer,
    panIndia: panIndiaReducer,
    apsrtc: apsrtcReducer,
    agentStore: agentStoreRedcuer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['order/createOrder/fulfilled']
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
