import axios, { AxiosResponse } from "axios";

let baseUrl = process.env.REACT_APP_ENQUIRY_BASE_URL;
let nodeUrl = process.env.REACT_APP_NODE_BASE_URL;

// axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = baseUrl;
// axios.defaults.headers.post['Access-Control-Allow-Credentials'] = true;
// axios.defaults.headers.post['Authorization'] = `Bearer ${token}`;

interface EnquiryData { company: string, name: string, phone: string, email: string, platform: string, approxOrders: string, website?: string }

export function sendEnquiry(formData: EnquiryData) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseUrl}/enquiries`,
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: formData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

interface UserEnquiryData { name: string, email: string; }

export function sendUserEnquiry(formData: UserEnquiryData) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseUrl}/enquiries`,
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: formData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export function sendBusinessEnquiry(formData: any) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeUrl}/create-business-enquiry`,
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: formData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export function sendReview(formData: any) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeUrl}/reviews`,
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: formData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export function sendNoOptionEnquiry(formData: any) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeUrl}/no-option-enquiry`,
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: formData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}