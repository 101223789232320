import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export interface Person {
    name?: string,
    phone?: string,
    address?: {
        flatno?: string,
        building?: string,
    },
    email?: string
}

export interface Address {
    identifier?: string,
    address: string,
    pincode: string,
    city?: string,
    state?: string,
    lat: number,
    lng: number,
    country?: string,
}


export interface PanIndiaState {
    service: string;
    startPerson: Person;
    endPerson: Person;
    startAddressSearch: Address;
    endAddressSearch: Address;
    startAddress: Address;
    endAddress: Address;
    startCitySearch: string;
    endCitySearch: string;
    startCity: string;
    endCity: string;
}

const initialState: PanIndiaState = {
    service: '',
    startPerson: {
        name: undefined,
        phone: undefined,
        address: {
            flatno: '',
            building: '',
        },
    },
    endPerson: {
        name: undefined,
        phone: undefined,
        address: {
            flatno: '',
            building: '',
        },
    },
    startAddressSearch: {
        identifier: '',
        address: '',
        city: '',
        state: '',
        pincode: '',
        lat: 0,
        lng: 0
    },
    endAddressSearch: {
        identifier: '',
        address: '',
        city: '',
        state: '',
        pincode: '',
        lat: 0,
        lng: 0
    },
    startAddress: {
        identifier: '',
        address: '',
        city: '',
        state: '',
        pincode: '',
        lat: 0,
        lng: 0,
        country: ''
    },
    endAddress: {
        identifier: '',
        address: '',
        city: '',
        state: '',
        pincode: '',
        lat: 0,
        lng: 0,
        country: ''
    },
    startCitySearch: '',
    endCitySearch: '',
    startCity: '',
    endCity: ''
}

export const panIndiaSlice = createSlice({
    name: 'pan-india',
    initialState,
    reducers: {
        setPanIndiaStartAddressSearch: (state, action) => {
            state.startAddressSearch = {
                ...state.startAddress,
                ...action.payload
            }
        },
        setPanIndiaEndAddressSearch: (state, action) => {
            state.endAddressSearch = {
                ...state.endAddress,
                ...action.payload
            }
        },
        setPanIndiaStartAddress: (state, action) => {
            state.startAddress = {
                ...state.startAddress,
                ...action.payload
            }
        },
        setPanIndiaEndAddress: (state, action) => {
            state.endAddress = {
                ...state.endAddress,
                ...action.payload
            }
        },
        setPanIndiaServiceType: (state, action) => {
            state.service = action.payload
        },
        setPanIndiaStartPerson: (state, action) => {
            state.startPerson = {
                ...state.startPerson,
                ...action.payload
            }
        },
        setPanIndiaEndPerson: (state, action) => {
            state.endPerson = {
                ...state.endPerson,
                ...action.payload
            }
        },
        setPanIndiaStartCitySearch: (state, action) => {
            state.startCitySearch = action.payload
        },
        setPanIndiaEndCitySearch: (state, action) => {
            state.endCitySearch = action.payload
        },
        setPanIndiaStartCity: (state, action) => {
            state.startCity = action.payload
        },
        setPanIndiaEndCity: (state, action) => {
            state.endCity = action.payload
        }
    }
})

export const { setPanIndiaStartAddressSearch, setPanIndiaEndAddressSearch, setPanIndiaStartAddress, setPanIndiaEndAddress, setPanIndiaServiceType, setPanIndiaStartPerson, setPanIndiaEndPerson, setPanIndiaEndCitySearch, setPanIndiaStartCitySearch, setPanIndiaEndCity, setPanIndiaStartCity } = panIndiaSlice.actions

export const getPanIndiaStartAddressSearch = (state: RootState) => state.panIndia.startAddressSearch
export const getPanIndiaEndAddressSearch = (state: RootState) => state.panIndia.endAddressSearch

export const getPanIndiaService = (state: RootState) => state.panIndia.service
export const getPanIndiaStartAddress = (state: RootState) => state.panIndia.startAddress
export const getPanIndiaEndAddress = (state: RootState) => state.panIndia.endAddress
export const getPanIndiaStartPerson = (state: RootState) => state.panIndia.startPerson
export const getPanIndiaEndPerson = (state: RootState) => state.panIndia.endPerson

export const getPanIndiaStartCitySearch = (state: RootState) => state.panIndia.startCitySearch
export const getPanIndiaEndCitySearch = (state: RootState) => state.panIndia.endCitySearch

export const getPanIndiaStartCity = (state: RootState) => state.panIndia.startCity
export const getPanIndiaEndCity = (state: RootState) => state.panIndia.endCity

export default panIndiaSlice.reducer