import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { confirmExpressDelOrder, initExpressDelOrder, searchSameDayDelShipper, UpdateData, UpdateExpressOrder, UpdateExpressPricing } from "../../api/panindia/expressdelivery";
import { AppThunk, RootState } from "../../store";
import { getUser } from "../auth.reducer";
import { getPanIndiaEndAddress, getPanIndiaEndCity, getPanIndiaService, getPanIndiaStartAddress, getPanIndiaStartCity } from "./panindia.reducer";

export interface Person {
    name?: string,
    phone?: string,
    address?: string,
    email?: string,
}
export interface Order {
    shipper: {
        name: string,
        fullName: string,
        logoURL: string
    },
    estimate: {
        time: number,
        cost: number,
        costBeforeDiscount?: number,
        gst?: number,
        platformCharges?: number
    },
    coupon?: string
}

export interface OrderState {
    fetching: boolean;
    fetchingError: boolean;
    // startAddress: Address;
    // endAddress: Address;
    startPerson: Person,
    endPerson: Person,
    orderDetails: Order;
    weight: number;
    // service?: string;
    pickupCityBounds: any;
    destinationCityBounds: any;
    results: any[] | undefined;
    pkgs: any[];
    fragile: boolean;
    commodity_value: number;
    gst: string;
    bookedAt: any;
    purposeType: string; //Business or personal
    packageImgURL: string;
}

const initialState: OrderState = {
    fetching: false,
    fetchingError: false,
    startPerson: {
        name: undefined,
        phone: undefined,
        email: undefined,
        address: '',
    },
    endPerson: {
        name: undefined,
        phone: undefined,
        email: undefined,
        address: '',
    },
    orderDetails: {
        shipper: {
            name: '',
            fullName: '',
            logoURL: ''
        },
        estimate: {
            time: 0,
            cost: 0,
            costBeforeDiscount: 0
        }
    },
    // service: '',
    weight: 0,
    pickupCityBounds: {
        sw: { lat: 0, lng: 0 },
        ne: { lat: 0, lng: 0 }
    },
    destinationCityBounds: {
        sw: { lat: 0, lng: 0 },
        ne: { lat: 0, lng: 0 }
    },
    results: undefined,
    pkgs: [],
    fragile: false,
    commodity_value: 0,
    gst: '',
    bookedAt: undefined,
    purposeType: '',
    packageImgURL: ''
}

export interface SearchData {
    service: string | undefined,
    start: {
        originPin: string,
        originState: string | undefined,
        originCity: string | undefined
    },
    end: {
        destinationPin: string,
        destinationState: string | undefined,
        destinationCity: string | undefined
    },
    weight: number,
    shipmentMode: string,
    shipmentStatus: string
}


const searchExpressShipperCall = createAsyncThunk(
    '/pan-india/searchShipper',
    async (searchQuery: SearchData) => {
        const response = await searchSameDayDelShipper(searchQuery)
        return response.response
    }
)

export const createExpressDelOrderAsync = createAsyncThunk(
    'pan-india/initOrder',
    async (data: any) => {

        console.log("DATA: ", data);
        const response = await initExpressDelOrder(data);
        // The value we return becomes the `fulfilled` action payload
        return response;

    }
);

export const confirmExpressDelOrderCall = createAsyncThunk(
    'order/confirmExpressOrder',
    async (data: any) => {

        console.log("DATA: ", data);
        const response = await confirmExpressDelOrder(data);
        return response.response;

    }
);

export const updateExpressOrderCall = createAsyncThunk(
    '/order/resetExpressOrder',
    async (data: any) => {
        console.log('EXPRESS RESET DATA = ', data);
        const response = await UpdateExpressOrder(data);
        console.log(response);

        const payload = {
            res: response.response,
            coupon: data.coupon
        }

        return payload
    }
)

export const updateExpressPricingCall = createAsyncThunk(
    '/order/updateExpressPricing',
    async (data: any) => {
        // console.log('EXPRESS RESET DATA = ', data);
        const response = await UpdateExpressPricing(data.orderData);
        console.log(response);

        const payload = {
            res: response.response,
            coupon: data.coupon
        }

        return payload
    }
)

export const updateExpressOrderAsync = (couponCode?: string): AppThunk => async (
    dispatch,
    getState
) => {
    const startAddress = getPanIndiaStartAddress(getState())
    const startCity = getPanIndiaStartCity(getState())
    const endCity = getPanIndiaEndCity(getState())
    const endAddress = getPanIndiaEndAddress(getState())
    const weight = getExpressDelWeight(getState())
    const user = getUser(getState())
    const orderDetails = getExpressOrderDetails(getState())

    const start = {
        originState: startAddress.state,
        originPin: startAddress.pincode,
        originCity: startAddress.city
    }
    const end = {
        destinationState: endAddress.state,
        destinationPin: endAddress.pincode,
        destinationCity: endAddress.city
    }

    let searchQuery: UpdateData = {
        start: start,
        end: end,
        weight: weight,
        user: user.uid,
        shipperID: orderDetails.shipper.name
    }

    if (couponCode) searchQuery.coupon = couponCode;

    dispatch(updateExpressOrderCall(searchQuery))
}

export const searchExpressShipperAsync = (): AppThunk => async (
    dispatch,
    getState
) => {
    const startAddress = getPanIndiaStartAddress(getState())
    const endAddress = getPanIndiaEndAddress(getState())
    const startCity = getPanIndiaStartCity(getState())
    const endCity = getPanIndiaEndCity(getState())
    let searchParams: SearchData = {
        service: getPanIndiaService(getState()),
        start: {
            originPin: startAddress.pincode.toString(),
            originState: startAddress.state?.toString(),
            originCity: startCity
        },
        end: {
            destinationPin: endAddress.pincode.toString(),
            destinationState: endAddress.state?.toString(),
            destinationCity: endCity
        },
        weight: getExpressDelWeight(getState()),
        shipmentMode: "E",
        shipmentStatus: "Delivered"
    }

    await dispatch(searchExpressShipperCall(searchParams))
}

export const expressDeliverySlice = createSlice({
    name: 'express_delivery',
    initialState,
    reducers: {
        clearExpressDelShippers: (state) => {
            state.results = undefined;
        },
        setExpressDelStartPerson: (state, action) => {
            state.startPerson = {
                ...action.payload
            }
        },
        setExpressDelEndPerson: (state, action) => {
            state.endPerson = {
                ...action.payload
            }
        },
        setExpressDelBookingDate: (state, action) => {
            state.bookedAt = action.payload
        },
        setExpressOrderDetails: (state, action) => {
            state.orderDetails = action.payload
        },
        setExpressDelWeight: (state, action) => {
            state.weight = action.payload
        },
        setExpressDelPkgs: (state, action) => {
            state.pkgs = action.payload
        },
        setExpressShipmentDetails: (state, action) => {
            state.fragile = action.payload.fragile
            state.commodity_value = action.payload.commodity_value
            state.gst = action.payload.gst
        },
        setExpressDelPurposeType: (state, action) => {
            state.purposeType = action.payload
        },
        setExpresspackageImgURL: (state, action) => {
            state.packageImgURL = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchExpressShipperCall.pending, (state) => {
                state.fetching = true;
            })
            .addCase(searchExpressShipperCall.fulfilled, (state, action) => {
                state.fetching = false;
                state.results = action.payload.data.data
            })
            .addCase(searchExpressShipperCall.rejected, (state) => {
                state.fetching = false
                state.results = []
            })

            .addCase(createExpressDelOrderAsync.pending, (state) => {
                state.fetching = true;
            })
            .addCase(createExpressDelOrderAsync.fulfilled, (state, action) => {
                state.fetching = false;
            })
            .addCase(createExpressDelOrderAsync.rejected, (state) => {
                state.fetching = false
            })

            .addCase(confirmExpressDelOrderCall.pending, (state) => {
                state.fetching = true;
                state.fetchingError = true;
            })
            .addCase(confirmExpressDelOrderCall.fulfilled, (state, action) => {
                state.fetching = false;
                state.fetchingError = false;
                // action.payload.navigate('/orders/'+action.payload.orderId);
            })
            .addCase(confirmExpressDelOrderCall.rejected, (state) => {
                state.fetching = false
                alert("Something went wrong while confirming order.");
            })

            .addCase(updateExpressOrderCall.pending, (state) => {
                state.fetching = true
            })
            .addCase(updateExpressOrderCall.fulfilled, (state, action) => {
                state.fetching = false
                const { res, coupon } = action.payload
                const { amount, breakdown } = res.data.data.estimate
                state.orderDetails.estimate.cost = amount
                state.orderDetails.estimate.costBeforeDiscount = breakdown.baseCost
                state.orderDetails.estimate.gst = breakdown.gst
                state.orderDetails.estimate.platformCharges = breakdown.platformChgs
                if (coupon) {
                    state.orderDetails.coupon = coupon
                } else {
                    state.orderDetails.coupon = undefined
                }
            })
            .addCase(updateExpressOrderCall.rejected, (state) => {
                state.fetching = false
            })

            .addCase(updateExpressPricingCall.pending, (state) => {
                state.fetching = true
            })
            .addCase(updateExpressPricingCall.fulfilled, (state, action) => {
                state.fetching = false
                const { res, coupon } = action.payload
                const { amount, breakdown } = res.data.data.estimate
                state.orderDetails.estimate.cost = breakdown.baseCost
                // state.orderDetails.estimate.costBeforeDiscount = breakdown.baseCost
                state.orderDetails.estimate.gst = breakdown.gst
                state.orderDetails.estimate.platformCharges = breakdown.platformChgs
                if (coupon) {
                    state.orderDetails.coupon = coupon
                } else {
                    state.orderDetails.coupon = undefined
                }
            })
            .addCase(updateExpressPricingCall.rejected, (state) => {
                state.fetching = false
            })
    }
})

export const { clearExpressDelShippers, setExpressDelStartPerson, setExpressDelEndPerson, setExpressOrderDetails, setExpressDelBookingDate, setExpressDelPkgs, setExpressShipmentDetails, setExpressDelPurposeType, setExpressDelWeight, setExpresspackageImgURL } = expressDeliverySlice.actions;

export const getExpressStartPerson = (state: RootState) => state.expressDelivery.startPerson
export const getExpressEndPerson = (state: RootState) => state.expressDelivery.endPerson

export const getExpressDelBookingDate = (state: RootState) => state.expressDelivery.weight
export const getExpressDelWeight = (state: RootState) => state.expressDelivery.weight
export const getExpressDelPkgs = (state: RootState) => state.expressDelivery.pkgs
export const getExpressDelFragile = (state: RootState) => state.expressDelivery.fragile
export const getExpressDelGST = (state: RootState) => state.expressDelivery.gst
export const getExpressDelCommodity = (state: RootState) => state.expressDelivery.commodity_value
export const getExpressDelPurposeType = (state: RootState) => state.expressDelivery.purposeType
export const getExpressDelResults = (state: RootState) => state.expressDelivery.results
export const getExpressDelFetching = (state: RootState) => state.expressDelivery.fetching
export const getExpressOrderDetails = (state: RootState) => state.expressDelivery.orderDetails
export const getExpressDelCoupon = (state: RootState) => state.expressDelivery.orderDetails.coupon
export const getExpresspackageImgURL = (state: RootState) => state.expressDelivery.packageImgURL

export default expressDeliverySlice.reducer