import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";

import { sendUserEnquiry } from "../../app/api/enquiry"

interface FormProps{
    isVisible: boolean;
    handleClose: (() => void);
}

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PHONE_REGEX = /^(\+91|\+91\-|0)?[789]\d{9}$/;


export default function ContactUsForm({isVisible,handleClose}:FormProps):JSX.Element {
  const [status, setStatus] = useState<0|1|2|3|4>(0); //0: idle, 1:sending, 2:success, 3:error, 4:already exists
  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = data => {
    setStatus(1);
    sendUserEnquiry(data).then( (res: any) => {
      setStatus(2)
    }
    ).catch( (err: { error: number; }) => {
      console.log("ERR",err)
      if(err.error===406){
        setStatus(4)
      }else{
        setStatus(3)
      }
    }
    )
  };

  type Inputs = {
    name: string,
    // phone: string,
    email: string,
  };

  return (
      <Modal show={isVisible} onHide={handleClose}>

      {((status === 0) || (status === 1)|| (status === 3)) && <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Body>
          <div className="modal-title magenta-text">
              Get in touch with us.
          </div>

          <label className="modal-form-field">
            <text className="required">Contact Name:</text>
            <div className="modal-form-input">
              <input {...register("name", { required: true })} className={errors.name?"validation-error":""}/>
              {errors.name && <div className="error-text">Please enter your name.</div>}
            </div>
          </label>
          {/* <label className="modal-form-field">
            <text className="required">Phone Number:</text>
            <div className="modal-form-input">
              <input {...register("phone", { pattern: PHONE_REGEX })} className={errors.phone?"validation-error":""}/>
              {errors.phone && <div className="error-text">Please enter a valid phone number.</div>}
            </div>
          </label> */}
          <label className="modal-form-field">
            <text className="required">Email:</text>
            <div className="modal-form-input">
              <input {...register("email", { required: true, pattern: EMAIL_REGEX  })} className={errors.email?"validation-error":""}/>
              {errors.email && <div className="error-text">Please enter a valid email address.</div>}
            </div>
          </label>
          {/* <form> */}
              {/* <label className="modal-form-field">
                  <text className="required">Company Name:</text>
                  <input
                      aria-label="Company Name"
                      name="company"
                      value={company} onChange={(e) => setCompany(e.target.value)}
                  />
              </label> */}
          {/* </form> */}
          {status===3 &&
            <div className="error-text" style={{textAlign:'center'}}>Something went wrong. Please try again.</div>
          }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={()=>handleClose()}>
          Close
        </Button>
        {status===1?
          <Button variant="primary" onClick={(e)=>e.preventDefault()}
            style={{width:80, display:'flex',flexDirection:'row',justifyContent:'center', cursor:'default'}}
          >
            <div className="loader" />
          </Button>
          :
          <input className="btn btn-primary" type="submit" style={{width:80}}/>
        }
      </Modal.Footer>
        </form>}
        {/* {status===2&& */}
        
        {status===2 &&
        <Modal.Body>
          <div className="modal-title magenta-text">
            Your response has been recieved!
          </div>
          <label className="modal-form-field">
            <text style={{width:'100%'}}>We will get in touch with you soon.</text>
          </label>
        </Modal.Body>
        }
        {status===4 &&
        <Modal.Body>
          <div className="modal-title magenta-text">
            It looks like you have already submitted your details
          </div>
          <label className="modal-form-field">
            <text style={{width:'100%'}}>We will get in touch with you soon.</text>
          </label>
        </Modal.Body>
        }
        {((status === 2) || (status === 4)) &&
        <Modal.Footer>
          <Button variant="success" onClick={()=>handleClose()}>
            OK
          </Button>
        </Modal.Footer>
        }
        
        {/* } */}

       
    </Modal>
  
  );

}