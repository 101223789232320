import { useState, useEffect } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation, faUserCircle } from "@fortawesome/free-solid-svg-icons";

import { editProfile } from "../../app/api/auth"
import { getUser, logOut, updateProfile } from "../../app/reducer/auth.reducer";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../app/store";
import { useAppSelector } from "../../app/hooks";
import { sendNoOptionEnquiry } from "../../app/api/enquiry";

interface FormProps{
    opened: boolean;
    handleClose: (() => void);
}

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PHONE_REGEX = /^(\+91|\+91\-|0)?[789]\d{9}$/;

type Inputs = {
  name: string,
  phone: string,
  email: string,
  source: {
    city: string,
    pincode: string
  },
  destination: {
    country: string,
    pincode: string
  },
  packaging: boolean
};

export default function InternationalNoOptionsForm({opened,handleClose}:FormProps):JSX.Element {
  const userData = useAppSelector(getUser);

  const [status, setStatus] = useState<0|1|2|3|4>(0); //0: idle, 1:sending, 2:success, 3:error, 4:already exists

  const { register, handleSubmit, formState: { errors } } = useForm<(Inputs)>({
    defaultValues: {
      name:userData.full_name?userData.full_name:'',
      phone:userData.phone?userData.phone:'',
      email:userData.email?userData.email:''
    }
  });

  const onSubmit: SubmitHandler<Inputs> = data => {
    const params = {
      mode:'international',
      ...data
    }
    setStatus(1);
    sendNoOptionEnquiry(params).then( res => {
      setStatus(2)
      console.log("ENQ", res);
    }
    ).catch( err => {
      console.log("ERR",err)
        setStatus(3)
    }
    )
  };


  return (
      <Modal show={opened} onHide={handleClose}>
        <Modal.Header>
          <div style={{display:'flex', flexDirection:'row', fontSize:'15px', fontWeight: 600, justifyContent:'space-between'}}>
            <div>Your Shipping Requirement</div>
          </div>
        </Modal.Header>

      <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Body>
          <Row>
            {/* <Col sm={12} lg={6}>
              <div className="d-flex flex-column justify-content-center">
                  <div className="text-uppercase form-input-label" style={{ color: "#ABABAB" }}>
                      Name*
                  </div>
                  <div>
                      <input
                          style={{ width: '100%' }}
                          {...register("name", { required: true })}
                          className={"form-control form-input " + (errors.name ? "error-border" : "")}
                      />
                      {errors.name && <div className="error-text"><FontAwesomeIcon icon={faCircleExclamation} />&nbsp;Your name is required</div>}
                  </div>
              </div>
            </Col>
            <Col sm={12} lg={6}>
              <div className="d-flex flex-column justify-content-center">
                  <div className="text-uppercase form-input-label" style={{ color: "#ABABAB" }}>
                      Phone*
                  </div>
                  <div>
                      <input
                          style={{ width: '100%' }}
                          type='number'
                          {...register("phone", { required: true, pattern: PHONE_REGEX })}
                          className={"form-control form-input " + (errors.phone ? "error-border" : "")}
                      />
                      {errors.phone && <div className="error-text"><FontAwesomeIcon icon={faCircleExclamation} />&nbsp;Please enter a valid phone number.</div>}
                  </div>
              </div>
            </Col>
            <Col sm={12} lg={6}>
              <div className="d-flex flex-column justify-content-center">
                  <div className="text-uppercase form-input-label" style={{ color: "#ABABAB" }}>
                      Email*
                  </div>
                  <div>
                      <input
                          style={{ width: '100%' }}
                          {...register("phone", { required: true, pattern: EMAIL_REGEX })}
                          className={"form-control form-input " + (errors.phone ? "error-border" : "")}
                      />
                      {errors.phone && <div className="error-text"><FontAwesomeIcon icon={faCircleExclamation} />&nbsp;Please enter a valid phone number.</div>}
                  </div>
              </div>
            </Col> */}
            <Col sm={12}>
              <div style={{fontWeight:600, fontSize:'15px', marginBottom:'-10px'}}>SOURCE:</div>
            </Col>
            <Col sm={12} lg={6}>
              <div className="d-flex flex-column justify-content-center">
                  <div className="text-uppercase form-input-label" style={{ color: "#ABABAB" }}>
                      Source City*
                  </div>
                  <div>
                      <input
                          style={{ width: '100%' }}
                          {...register("source.city", { required: true })}
                          className={"form-control form-input " + (errors.source?.city ? "error-border" : "")}
                      />
                      {errors.source?.city && <div className="error-text"><FontAwesomeIcon icon={faCircleExclamation} />&nbsp;Please enter a valid phone number.</div>}
                  </div>
              </div>
            </Col>
            <Col sm={12} lg={6}>
              <div className="d-flex flex-column justify-content-center">
                  <div className="text-uppercase form-input-label" style={{ color: "#ABABAB" }}>
                      Source Pincode*
                  </div>
                  <div>
                      <input
                          style={{ width: '100%' }}
                          type='number'
                          {...register("source.pincode", { required: true })}
                          className={"form-control form-input " + (errors.source?.pincode ? "error-border" : "")}
                      />
                      {errors.source?.pincode && <div className="error-text"><FontAwesomeIcon icon={faCircleExclamation} />&nbsp;Please enter a valid phone number.</div>}
                  </div>
              </div>
            </Col>
            <Col sm={12}>
              <div style={{fontWeight:600, fontSize:'15px', marginTop:'15px', marginBottom:'-10px'}}>DESTINATION:</div>
            </Col>
            <Col sm={12} lg={6}>
              <div className="d-flex flex-column justify-content-center">
                  <div className="text-uppercase form-input-label" style={{ color: "#ABABAB" }}>
                      Destination Country*
                  </div>
                  <div>
                      <input
                          style={{ width: '100%' }}
                          {...register("destination.country", { required: true })}
                          className={"form-control form-input " + (errors.destination?.country ? "error-border" : "")}
                      />
                      {errors.destination?.country && <div className="error-text"><FontAwesomeIcon icon={faCircleExclamation} />&nbsp;Please enter a valid phone number.</div>}
                  </div>
              </div>
            </Col>
            <Col sm={12} lg={6}>
              <div className="d-flex flex-column justify-content-center">
                  <div className="text-uppercase form-input-label" style={{ color: "#ABABAB" }}>
                      Destination Pincode*
                  </div>
                  <div>
                      <input
                          style={{ width: '100%' }}
                          type='number'
                          {...register("destination.pincode", { required: true })}
                          className={"form-control form-input " + (errors.destination?.pincode ? "error-border" : "")}
                      />
                      {errors.destination?.pincode && <div className="error-text"><FontAwesomeIcon icon={faCircleExclamation} />&nbsp;Please enter a valid phone number.</div>}
                  </div>
              </div>
            </Col>
          </Row>
          {/* <form> */}
              {/* <label className="modal-form-field">
                  <text className="required">Company Name:</text>
                  <input
                      aria-label="Company Name"
                      name="company"
                      value={company} onChange={(e) => setCompany(e.target.value)}
                  />
              </label> */}
          {/* </form> */}
          {status===3 &&
            <div className="error-text" style={{textAlign:'center'}}>Something went wrong. Please try again.</div>
          }
      </Modal.Body>
      {status!==2 && <Modal.Footer>
        <Button variant="secondary" onClick={()=>handleClose()} disabled={status===1}>
          Close
        </Button>
        {status===1?
          <Button variant="primary" onClick={(e)=>e.preventDefault()}
            style={{width:80, display:'flex',flexDirection:'row',justifyContent:'center', cursor:'default'}}
          >
            <div className="loader" />
          </Button>
          :
          <input className="btn btn-primary" type="submit" value="Send"/>
        }
      </Modal.Footer>}
        </form>
        {/* {status===2&& */}
        
        {status===2 &&
        <Modal.Footer>
          <label className="modal-form-field">
            <text style={{width:'100%'}}>Your enquiry has been sent.</text>
          </label>
          <Button variant="success" onClick={()=>handleClose()}>
            OK
          </Button>
        </Modal.Footer>
        }

       
    </Modal>
  
  );

}