import axios, { AxiosResponse } from "axios";
import { checkLogin } from "../firebase";

let ecsBaseUrl = process.env.REACT_APP_ECS_BASE_URL + "";

export async function getStoreAPI(agentUID: string) {
    let authToken = '';
    try {
        await checkLogin().then((res: any) => { authToken = res?.stsTokenManager?.accessToken || '' })
    } catch (e) {
        console.log("ERR", e);
    }
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {

        axios({
            url: `${ecsBaseUrl}/get-store-details`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken),
                stakeholder: "customer"
            },
            params: {
                type: "customer",
                storeURL: agentUID
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function getCustomerStoreDataAPI(agentUID: string) {
    let authToken = '';
    try {
        await checkLogin().then((res: any) => { authToken = res?.stsTokenManager?.accessToken || '' })
    } catch (e) {
        console.log("ERR", e);
    }

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {

        axios({
            url: `${ecsBaseUrl}/get-agent-customer-details`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken),
                stakeholder: "aggregator"
            },
            params: {
                referrerAgentOrgUid: agentUID,
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function updateCustomerStoreDataAPI(payload: { name: string }) {
    let authToken = '';
    try {
        await checkLogin().then((res: any) => { authToken = res?.stsTokenManager?.accessToken || '' })
    } catch (e) {
        console.log("ERR", e);
    }

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {

        axios({
            url: `${ecsBaseUrl}/update-agent-customer`,
            method: "PUT",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken),
                stakeholder: "aggregator"
            },
            data: {
                payload,
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function createCustomerStoreDataAPI(agentUID: string, phone: string) {
    let authToken = '';
    try {
        await checkLogin().then((res: any) => { authToken = res?.stsTokenManager?.accessToken || '' })
    } catch (e) {
        console.log("ERR", e);
    }

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {

        axios({
            url: `${ecsBaseUrl}/create-agent-customer`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken),
                stakeholder: "aggregator"
            },
            data: {
                referrerAgentOrgUid: agentUID,
                payload: {
                    contact: phone
                }
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function getAgentCustomerAPI(token?: string) {
    let authToken = token;
    if (!token) {
        try {
            await checkLogin().then((res: any) => { authToken = res?.stsTokenManager?.accessToken || '' })
        } catch (e) {
            console.log("ERR", e);
        }
    }
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {

        axios({
            url: `${ecsBaseUrl}/user`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken),
                stakeholder: "customer"
            },
            params: {
                type: "customer",
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function getAgentCustomerWalletAPI(token?: string) {
    let authToken = token;
    if (!token) {
        try {
            await checkLogin().then((res: any) => { authToken = res?.stsTokenManager?.accessToken || '' })
        } catch (e) {
            console.log("ERR", e);
        }
    }
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {

        axios({
            url: `${ecsBaseUrl}/get-transactions`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken),
                stakeholder: "customer"
            },
            params: {
                orgType: "customer",
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function createAgentCustomerAPI(token: string, payload: any) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${ecsBaseUrl}/create-organization`,
            method: "POST",
            headers: {
                Accept: "application/json",
                "customer-authorization": "Bearer " + (token),
                stakeholder: "customer"
            },
            data: payload
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export interface addressPayload {
    package:
    {
        fragile: boolean,
        category: string,
        weight: number,
        l: number,
        b: number,
        h: number
    }[],
    weight: number,
    start: {
        lat: number,
        lng: number,
        address: string,
        pincode: string,
        state: string,
        city?: string,
        country?: string
    },
    end: {
        lat: number,
        lng: number,
        address: string,
        pincode: string,
        state: string,
        city?: string,
        country?: string
    },
    service: string,
    agentOrgUid?: string
}

export async function searchAgentProximityAPI(payload: { lat: number, lng: number, city: string, agentOrgUid: string }) {
    let authToken = '';
    try {
        await checkLogin().then((res: any) => { authToken = res?.stsTokenManager?.accessToken || '' })
    } catch (e) {
        console.log("ERR", e);
    }
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {

        axios({
            url: `${ecsBaseUrl}/search-agent-proximity`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : ''),
                stakeholder: "customer"
            },
            data: payload
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export interface searchPayload {
    package:
    {
        fragile: boolean,
        category: string,
        weight: number,
        l: number,
        b: number,
        h: number
    }[],
    weight: number,
    start: {
        lat: number,
        lng: number,
        address: string,
        pincode: string,
        state: string,
        city?: string,
        country?: string
    },
    end: {
        lat: number,
        lng: number,
        address: string,
        pincode: string,
        state: string,
        city?: string,
        country?: string
    },
    service: string,
    agentOrgUid?: string
}

export async function searchAgentShippersAPI(payload: searchPayload) {
    let authToken = '';
    try {
        await checkLogin().then((res: any) => { authToken = res?.stsTokenManager?.accessToken || '' })
    } catch (e) {
        console.log("ERR", e);
    }
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {

        axios({
            url: `${ecsBaseUrl}/search-shipper`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : ''),
                stakeholder: "customer"
            },
            data: payload
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export interface orderPayload {
    shipperId: string,
    package:
    {
        fragile: boolean,
        category: string,
        weight: number,
        l: number,
        b: number,
        h: number
    }[],
    weight: number,
    start: {
        contact: {
            name: string,
            phone: string,
            email?: string
        },
        address: {
            houseNo: string,
            buildingName: string,
            mapData: {
                lat: number,
                lng: number,
                address: string,
                pincode: string,
                state: string,
                city?: string,
                country?: string
            }
        }
    },
    end: {
        contact: {
            name: string,
            phone: string,
            email?: string
        },
        address: {
            houseNo: string,
            buildingName: string,
            mapData: {
                lat: number,
                lng: number,
                address: string,
                pincode: string,
                state: string,
                city?: string,
                country?: string
            }
        }
    },
    orderType: string,
    agentOrgUid: string,
    optedOutOfPickup?: true
}

export async function placeAgentOrderAPI(payload: any) {
    let authToken = '';
    try {
        await checkLogin().then((res: any) => { authToken = res?.stsTokenManager?.accessToken || '' })
    } catch (e) {
        console.log("ERR", e);
    }
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {

        axios({
            url: `${ecsBaseUrl}/create-pan-order?type=agent`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : ''),
                stakeholder: "aggregator"
            },
            data: payload
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response })
            });

    });
}

export async function getAllAgentOrdersAPI(params: { custOrgId: string, orderStatus?: string, orderType?: string, page?: number, limit?: number, searchString?: string }) {
    let authToken = '';
    try {
        await checkLogin().then((res: any) => { authToken = res?.stsTokenManager?.accessToken || '' })
    } catch (e) {
        console.log("ERR", e);
    }

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${ecsBaseUrl}/get-all-orders`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : ''),
                stakeholder: 'customer'
            },
            params: params
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function getOrderDetailAPI(orderId: string) {
    let authToken = '';
    try {
        await checkLogin().then((res: any) => { authToken = res?.stsTokenManager?.accessToken || '' })
    } catch (e) {
        console.log("ERR", e);
    }
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {

        axios({
            url: `${ecsBaseUrl}/get-store-details`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken),
                stakeholder: "customer"
            },
            params: {
                sm_orderid: orderId
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}


