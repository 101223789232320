import axios, { AxiosResponse } from "axios";
import { checkLogin } from "./firebase";

let baseUrl = process.env.REACT_APP_ENQUIRY_BASE_URL;
let token = process.env.REACT_APP_BEARER_TOKEN;
const localhostUrl = 'http://localhost:8000'
const nodeBaseUrl = process.env.REACT_APP_NODE_BASE_URL;

// axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = baseUrl;
// // axios.defaults.headers.post['Access-Control-Allow-Credentials'] = true;
// axios.defaults.headers.post['Authorization'] = `Bearer ${token}`;

export interface SearchData {
    start: {
        lat: number,
        lng: number,
        address: string,
        pincode: number
    };
    end: {
        lat: number,
        lng: number,
        address: string,
        pincode: number
    };
    vehicle?: number;
    city: string,
    weight: number
}

export interface SearchData4W {
    start: {
        lat: number,
        lng: number,
        address: string,
        pincode: number
    };
    end: {
        lat: number,
        lng: number,
        address: string,
        pincode: number
    };
    vehicle: number;
    city: string;
}

export interface SearchData4WRental {
    hours: number;
    vehicle: string;
    city: string;
}

export interface UpdateData {
    shipperID: string,
    start: {
        address: {
            fromGoogle: {
                lat: number,
                lng: number,
                address: string,
                pincode: number
            },
            flatno: string,
            building: string,
            address_line_2?: string
        },
        contact: {
            name: string,
            phone: string
        }
    };
    end: {
        address: {
            fromGoogle: {
                lat: number,
                lng: number,
                address: string,
                pincode: number
            },
            flatno: string,
            building: string,
            address_line_2?: string
        },
        contact: {
            name: string,
            phone: string
        }
    };
    city: string;
    weight: number;
    coupon?: string;
    vehicle: number;
    user: string;
    hours?: number;
    rentalVehicle?: string
}

export interface UpdateRentalData {
    shipperID: string,
    start: {
        address: {
            fromGoogle: {
                lat: number,
                lng: number,
                address: string,
                pincode: number
            },
            flatno: string,
            building: string,
            address_line_2?: string
        },
        contact: {
            name: string,
            phone: string
        }
    };
    city: string;
    weight: number;
    coupon?: string;
    vehicle: string;
    hours: number;
    user: string
}

export interface OrderData {
    isSuperAdmin: boolean,
    payment_status: string,
    user: string,
    shipperID: string,
    start: {
        address: {
            fromGoogle: {
                lat: number,
                lng: number,
                address: string,
                pincode: number,
                city: string | undefined,
                state: string | undefined,
                country: string | undefined
            },
            flatno: string,
            building: string,
            address_line_2?: string
        },
        contact: {
            name: string,
            phone: string,
            email?: string
        }
    };
    end: {
        address: {
            fromGoogle: {
                lat: number,
                lng: number,
                address: string,
                pincode: number,
                city: string | undefined,
                state: string | undefined,
                country: string | undefined
            },
            flatno: string,
            building: string,
            address_line_2?: string
        },
        contact: {
            name: string,
            phone: string
        }
    };
    item: any[];
    weight: number;
    city: string;
    vehicle?: number;
    coupon?: string;
    helper?: boolean;
    order_type: string;
}

interface ConfirmOrderData extends OrderData {
    transactionID: string,
    orderID: string,
    email: string
}

interface ConfirmRentalOrderData extends OrderData {
    shipperID: string,
    city: string,
    orderID: string,
    transactionID: string,
    payment_status: string,
}

export interface RentalOrderData {
    isSuperAdmin: boolean,
    shipperID: string,
    city: string,
    vehicle: string,
    start: {
        contact: {
            name: string,
            phone: string,
            email: string,
            alternatePhone: string
        },
        address: {
            flatno: string,
            building: string,
            fromGoogle: {
                lat: number,
                lng: number,
                address: string,
                pincode: number
            }
        }
    },
    // address: string,
    bookedAt: any,
    hours: number,
    distance?: number,
    uid: string,
    coupon?: string
}

export function searchShipper(formData: SearchData) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/shippers/search`,
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: formData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export function searchShipper4W(formData: SearchData4W) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/hyperlocal-4-wheelers/search`,
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: formData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export function searchShipper4WRental(formData: SearchData4WRental) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/hyperlocal-4-wheelers/searchRental`,
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: formData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export async function createOrder(formData: OrderData) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        console.log(formData);

        axios({
            url: `${nodeBaseUrl}/shippers/initPayment`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            },
            data: formData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

//not required
export function createOrder4W(formData: OrderData) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        console.log(formData);

        axios({
            url: `${nodeBaseUrl}/shippers/initPayment`,
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: formData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function createOrderRental(formData: RentalOrderData) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        console.log(formData);

        axios({
            url: `${nodeBaseUrl}/hyperlocal-4-wheelers/initPaymentRental`,
            // url: `${baseUrl}/hyperlocal-4-wheelers/initPaymentRental`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            },
            data: formData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function updateQuote(formData: UpdateData) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/shippers/resetOrder`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            },
            data: formData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export function updateRentalQuote(formData: UpdateRentalData) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/hyperlocal-4-wheelers/resetOrder`,
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: formData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

//not required
export function confirmOrder(formData: ConfirmOrderData) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/shippers/createOrder`,
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: formData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

//not required
export function confirmOrder4W(formData: ConfirmOrderData) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/shippers/createOrder`,
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: formData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

//not required
export function confirmRentalOrder(formData: ConfirmRentalOrderData) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/hyperlocal-4-wheelers/createOrderRental`,
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: formData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function getAllOrders(formData: { user: string, page?: number, limit?: number }) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    const { user, page, limit } = formData;
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/orders/findOrders?page=${page}&limit=${limit}`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export function getOrderDetails(orderID: string) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/orders/getOrderDetails/${orderID}`,
            method: "GET",
            headers: {
                Accept: "application/json"
            },
            // data: formData
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function getAllAPSRTCOrders(formData: { user: string, page?: number, limit?: number }) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    const { user, page, limit } = formData;
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/apsrtc-orders/findOrders?page=${page}&limit=${limit}`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function getApsrtcOrderDetails(orderID: string) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/apsrtc-orders/getOrderDetails/${orderID}`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            },
            // data: formData
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function getAllIntOrders(formData: { user: string, page?: number, limit?: number }) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    const { user, page, limit } = formData
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/internationalorders/findOrders?page=${page}&limit=${limit}`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export function getOrderIntDetails(orderID: string) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/internationalorders/getOrderDetails/${orderID}`,
            method: "GET",
            headers: {
                Accept: "application/json"
            },
            // data: formData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function getAllRentalOrders(formData: { uid: string, page?: number, limit?: number }) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    const { uid, page, limit } = formData
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/hyperlocal-4-wheelers/findOrders?page=${page}&limit=${limit}`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            }
            // data: {
            //     uid: uid
            // }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export function getOrderRentalDetails(orderID: string) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/hyperlocal-4-wheelers/getOrderDetails/${orderID}`,
            method: "GET",
            headers: {
                Accept: "application/json"
            },
            // data: formData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}
