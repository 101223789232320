import axios, { AxiosResponse } from "axios";
import { checkLogin } from "../firebase";
import { checkLogin as checkLoginSaaS } from "../firebase_saas";

let ecsBaseUrl = process.env.REACT_APP_ECS_BASE_URL + "";

export async function searchAgentProximityAPI(lat: number, lng: number, city: string) {

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {

        axios({
            url: `${ecsBaseUrl}/search-agent-proximity`,
            method: "POST",
            headers: {
                Accept: "application/json",
                stakeholder: "customer"
            },
            data: {
                lat: lat,
                lng: lng,
                city: city
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function searchAllAgentsProximityAPI(lat: number, lng: number, city: string) {

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {

        axios({
            url: `${ecsBaseUrl}/search-all-agent-proximity`,
            method: "POST",
            headers: {
                Accept: "application/json",
                stakeholder: "customer"
            },
            data: {
                lat: lat,
                lng: lng,
                city: city
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function getApsrtcPricingAPI(orderPayload: any) {
    let authToken = '';
    try {
        await checkLogin().then((res: any) => { authToken = res?.stsTokenManager?.accessToken || '' })
    } catch (e) {
        console.log("ERR", e);
    }
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {

        axios({
            url: `${ecsBaseUrl}/search-shipper`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : ''),
                stakeholder: "customer"
            },
            data: { ...orderPayload, lmAgentOrgId: orderPayload.lmAgentOrgUid }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function getCustApsrtcPricingAPI(orderPayload: any) {
    let authToken = '';
    try {
        await checkLoginSaaS().then((res: any) => { authToken = res?.stsTokenManager?.accessToken || '' })
    } catch (e) {
        console.log("ERR", e);
    }
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {

        axios({
            url: `${ecsBaseUrl}/search-shipper`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : ''),
                stakeholder: "customer"
            },
            data: { ...orderPayload, lmAgentOrgId: orderPayload.lmAgentOrgUid }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function getApsrtcAggrePricingAPI(orderPayload: any) {
    let authToken = '';
    try {
        await checkLogin().then((res: any) => { authToken = res?.stsTokenManager?.accessToken || '' })
    } catch (e) {
        console.log("ERR", e);
    }
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {

        axios({
            url: `${ecsBaseUrl}/search-shipper`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : ''),
                stakeholder: "aggregator"
            },
            data: {...orderPayload, lmAgentOrgId: orderPayload.lmAgentOrgUid}
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function getApsrtcAggrePricingAPIV2(orderPayload: any) {
    let authToken = '';
    try {
        await checkLogin().then((res: any) => { authToken = res?.stsTokenManager?.accessToken || '' })
    } catch (e) {
        console.log("ERR", e);
    }
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {

        axios({
            url: `${ecsBaseUrl}/search-shipper/v2`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : ''),
                stakeholder: "aggregator"
            },
            data: { ...orderPayload, lmAgentOrgId: orderPayload.lmAgentOrgUid }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function initApsrtcOrderAPI(orderPayload: any) {
    let authToken = '';
    try {
        await checkLogin().then((res: any) => { authToken = res?.stsTokenManager?.accessToken || '' })
    } catch (e) {
        console.log("ERR", e);
    }
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {

        axios({
            url: `${ecsBaseUrl}/init-apsrtc-order`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken),
                stakeholder: "aggregator"
            },
            data: orderPayload
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function sendApsrtcOrderReqAPI(orderPayload: any) {
    let authToken = '';
    try {
        await checkLogin().then((res: any) => { authToken = res?.stsTokenManager?.accessToken || '' })
    } catch (e) {
        console.log("ERR", e);
    }
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {

        axios({
            url: `${ecsBaseUrl}/create-saas-request-order`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken),
                stakeholder: "aggregator"
            },
            data: orderPayload
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function getAllApsrtcOrders(formData: { page?: number, limit?: number }) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    const { page, limit } = formData;
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${ecsBaseUrl}/get-all-orders?page=${page}&limit=${limit}&shipperId=apsrtc`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : ''),
                "stakeholder": "aggregator"
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function cancelSaasOrderAPI(sm_orderid: string) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    let data = JSON.stringify({
        "sm_orderid": sm_orderid
    });

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            method: 'post',
            maxBodyLength: Infinity,
            url: `${ecsBaseUrl}/cancel-saas-order`,
            headers: {
                'Authorization': 'Bearer ' + (authToken ? authToken : ""),
                'Content-Type': 'application/json',
                'stakeholder': 'aggregator'
            },
            data: data
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });

}



export async function getApsrtcOrderDetails(orderID: string) {
    // let authToken = '';
    // await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${ecsBaseUrl}/get-public-order-details?sm_orderid=${orderID}`,
            method: "GET",
            headers: {
                Accept: "application/json",
            },
            // data: formData
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}